import geral from "./geral";
import * as usuario from "./usuarios";
import { authentication } from "./usuarios";

const schemas = {
  authentication,
  geral,
  usuario,
};

export default schemas;
