import React from "react";
import { createRoot } from "react-dom/client";

import { useState } from "react";
import { useEffect } from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin,
  Marker,
} from "@vis.gl/react-google-maps";

const ExampleMaps = () => {
  return (
    <div className="w-full h-[400px]">
      <APIProvider
        apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
        onLoad={() => console.log("Maps API has loaded.")}
      >
        <Map
          defaultZoom={13}
          defaultCenter={{ lat: -33.860664, lng: 151.208138 }}
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log(
              "camera changed:",
              ev.detail.center,
              "zoom:",
              ev.detail.zoom,
            )
          }
        ></Map>
      </APIProvider>
    </div>
  );
};

const ExampleMapsMyLocation = () => {
  const [location, setLocation] = useState<{
    lat: any;
    lng: any;
    error: any;
  }>({
    lat: 0,
    lng: null,
    error: "",
  });

  useEffect(() => {
    // Function to handle success of geolocation API
    const handleSuccess = (position: any) => {
      const { latitude, longitude } = position.coords;
      setLocation({
        lat: latitude,
        lng: longitude,
        error: "",
      });
    };

    // Function to handle errors from geolocation API
    const handleError = (error: any) => {
      setLocation({
        lat: null,
        lng: null,
        error: error.message,
      });
    };

    // Check if geolocation is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      // setLocation({
      //   lat: '',
      //   lat: '',
      //   error: 'Geolocation is not supported by this browser.'
      // });
    }
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="w-full h-[400px]">
      <APIProvider
        apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
        onLoad={() => console.log("Maps API has loaded.")}
      >
        <Map
          defaultZoom={13}
          defaultCenter={location}
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log(
              "camera changed:",
              ev.detail.center,
              "zoom:",
              ev.detail.zoom,
            )
          }
        ></Map>
      </APIProvider>
    </div>
  );
};

type Poi = { key: string; location: google.maps.LatLngLiteral };

const PoiMarkers = (props: { pois: Poi[] }) => {
  return (
    <>
      {props.pois.map((poi: Poi) => (
        <AdvancedMarker key={poi.key} position={poi.location}>
          <Pin
            background={"#FBBC04"}
            glyphColor={"#000"}
            borderColor={"#000"}
          />
        </AdvancedMarker>
      ))}
    </>
  );
};

const ExampleMapsMarkMyLocation = () => {
  // const locations: Poi[] = [
  //   {key: 'operaHouse', location: { lat: -33.8567844, lng: 151.213108  }},
  //   {key: 'tarongaZoo', location: { lat: -33.8472767, lng: 151.2188164 }},
  //   {key: 'manlyBeach', location: { lat: -33.8209738, lng: 151.2563253 }},
  //   {key: 'hyderPark', location: { lat: -33.8690081, lng: 151.2052393 }},
  //   {key: 'theRocks', location: { lat: -33.8587568, lng: 151.2058246 }},
  //   {key: 'circularQuay', location: { lat: -33.858761, lng: 151.2055688 }},
  //   {key: 'harbourBridge', location: { lat: -33.852228, lng: 151.2038374 }},
  //   {key: 'kingsCross', location: { lat: -33.8737375, lng: 151.222569 }},
  //   {key: 'botanicGardens', location: { lat: -33.864167, lng: 151.216387 }},
  //   {key: 'museumOfSydney', location: { lat: -33.8636005, lng: 151.2092542 }},
  //   {key: 'maritimeMuseum', location: { lat: -33.869395, lng: 151.198648 }},
  //   {key: 'kingStreetWharf', location: { lat: -33.8665445, lng: 151.1989808 }},
  //   {key: 'aquarium', location: { lat: -33.869627, lng: 151.202146 }},
  //   {key: 'darlingHarbour', location: { lat: -33.87488, lng: 151.1987113 }},
  //   {key: 'barangaroo', location: { lat: - 33.8605523, lng: 151.1972205 }},
  // ];

  const [location, setLocation] = useState<Poi>({
    key: "Estou aqui!",
    location: {
      lat: 0,
      lng: 0,
    },
  });

  useEffect(() => {
    // Function to handle success of geolocation API
    const handleSuccess = (position: any) => {
      const { latitude, longitude } = position.coords;
      setLocation({
        ...location,
        location: {
          lat: latitude,
          lng: longitude,
        },
      });
    };

    // Function to handle errors from geolocation API
    const handleError = (error: any) => {
      // setLocation({
      //   lat: null,
      //   lng: null,
      //   error: error.message,
      // });
    };

    // Check if geolocation is available
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    } else {
      // setLocation({
      //   lat: '',
      //   lat: '',
      //   error: 'Geolocation is not supported by this browser.'
      // });
    }
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div className="w-full h-[400px]">
      <APIProvider
        apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
        onLoad={() => console.log("Maps API has loaded.")}
      >
        <Map
          defaultZoom={13}
          defaultCenter={{ lat: 0, lng: 0 }}
          onCameraChanged={(ev: MapCameraChangedEvent) =>
            console.log(
              "camera changed:",
              ev.detail.center,
              "zoom:",
              ev.detail.zoom,
            )
          }
        >
          <Marker
            position={{ lat: 0, lng: 0 }}
            icon={"http://maps.google.com/mapfiles/ms/icons/purple.png"}
          />

          <Marker
            position={{ lat: 0, lng: 10000 }}
            icon={"http://maps.google.com/mapfiles/ms/icons/green.png"}
          />
        </Map>
      </APIProvider>
    </div>
  );
};

export default ExampleMapsMarkMyLocation;
