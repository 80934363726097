import { Navigate, Outlet } from "react-router-dom";
import { Logo } from "../../assets/svg";
import useAppInfo from "../../zustand/app-info-zus";
import "./style.css";

const Auth = () => {
  const { user } = useAppInfo();

  return user == undefined ? (
    <div className=" w-full gap-[60px] flex justify-center min-h-[600px] tablet:min-h-[500px] xl:min-h-[768px] items-center flex-col tablet:flex-row tablet:justify-evenly">
      <div className="flex flex-row items-center justify-center ">
        <img src={Logo} className="w-[80px] tablet:w-[150px]" />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={"/"} />
  );
};

export default Auth;
