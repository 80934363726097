import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import UpdateClient from "../../components/client/update-client";
import HeaderForm from "../../components/header-form";
import useViewLog from "../../hooks/use-view-log";
import { clientFormSchema } from "../../utils/schemas/client";
import tasks from "../../utils/tasks";
import useAppInfo from "../../zustand/app-info-zus";
import imgPreview from "./../../assets/img/img_preview.png";

const Actualizar = () => {
  const { id } = useParams();

  const [isLoading, setLoading] = useState(false);

  const { viewLog: viewLog2, setNewLog } = useViewLog();
  // STATE MANAGEMENT FOR CONTAINED SEARCH CLIENT

  const handleSubmitForm = (data: any) => {
    setLoading(true);
    api.post(
      {
        path: "/api/cliente/update-info",
        data: data,
      },
      (data) => {
        setLoading(false);
        setNewLog({
          type: "SUCCESS",
          message: "Cliente actualizado com sucesso.",
        });
      },
      (errors) => {
        setLoading(false);
        if (errors.status == HTTP_STATUS.BAD_REQUEST) {
          setNewLog({
            type: "WARNING",
            message: "Não foi encontrado cliente com essa especificação.",
          });
        } else if (errors.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão, tente novamente.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  const { user } = useAppInfo();

  const defaultValues = {
    nome: null,
    email: null,
    contacto1: null,
    contacto2: null,
    contacto3: null,
    contacto4: null,
    area_operacional: user?.area_operacional,
    bairro: user?.bairro,
    avenida_rua: null,
    n_casa_rua: null,
    flat: null,
    andar: null,
    numero_de_agregado: null,
    casa_propria: null,
    tem_recebido_factura: null,
    agua_sai_regularidade: null,
    agua_sai_regularidade_desc: null,
    estado_cavalete: null,
    cavalete_tem_fugas: null,
    estado_do_contador: null,
    diametro_contador: null,
    regularidade_leitura: null,
    avaliacao_adrmm: null,
    numero_contador: null,
    leitura_actual: null,
    data_registro: null,
    situacao_entrevistado: null,
    tipo_actividade: null,
    fonte_agua: null,
    fonte_agua_desc: null,
    suspeita_ilegal: null,
    foto_contador: imgPreview,
    foto_residencia: imgPreview,
    foto_balisa: imgPreview,
    latitude: null,
    longitude: null,
    observacao: null,
    sem_adrmm_porque: null,
    senhorio_nome: null,
    senhorio_contacto1: null,
    senhorio_contacto2: null,
    interessado_na_adrmm: null,
    tipo_registo: null,
    id_registrador: null,
    parceiro_negocio: null,
    conta_contrato: null,
    instalacao: null,
    unidade_leitura: null,
    zona: null,
    rua: null,
    tipo_instalacao: null,
  };

  return (
    <div className="w-full">
      <div className="flex flex-1 flex-col items-center">
        <div className=" flex px-[30px] flex-row w-full justify-center md:max-w-[600px] xl:max-w-[800px]">
          <Formik
            validationSchema={clientFormSchema}
            initialValues={defaultValues}
            onSubmit={(values, { resetForm }) => {
              handleSubmitForm({
                ...values,
                id: id,
              });
            }}
          >
            {({
              values,
              setValues,
              errors,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => {
              return (
                <div className="w-full">
                  <div className="pt-[40px] pb-[20px]">
                    <HeaderForm
                      title="Actualizar Entrevistado"
                      description="Actualização dos dados do entrevistado "
                    />
                  </div>
                  <HelperFindClient
                    id={Number(id)}
                    success={(data) => {
                      const client: any = data;
                      // setClient(data);
                      setFieldValue(
                        "nome",
                        tasks.verificarValor(client.nome, ""),
                      );
                      setFieldValue(
                        "email",
                        tasks.verificarValor(client.email, ""),
                      );
                      setFieldValue(
                        "contacto1",
                        tasks.verificarValor(client.contacto1, ""),
                      );
                      setFieldValue(
                        "contacto2",
                        tasks.verificarValor(client.contacto2, ""),
                      );
                      setFieldValue(
                        "contacto3",
                        tasks.verificarValor(client.contacto3, ""),
                      );
                      setFieldValue(
                        "contacto4",
                        tasks.verificarValor(client.contacto4, ""),
                      );
                      setFieldValue(
                        "area_operacional",
                        tasks.verificarValor(client.area_operacional, ""),
                      );
                      setFieldValue(
                        "bairro",
                        tasks.verificarValor(client.bairro, ""),
                      );
                      setFieldValue(
                        "avenida_rua",
                        tasks.verificarValor(client.avenida_rua, ""),
                      );
                      setFieldValue(
                        "n_casa_rua",
                        tasks.verificarValor(client.n_casa_rua, ""),
                      );
                      setFieldValue(
                        "flat",
                        tasks.verificarValor(client.flat, ""),
                      );
                      setFieldValue(
                        "andar",
                        tasks.verificarValor(client.andar, ""),
                      );
                      setFieldValue(
                        "numero_de_agregado",
                        tasks.verificarValor(client.numero_de_agregado, ""),
                      );
                      setFieldValue(
                        "casa_propria",
                        tasks.verificarValor(client.casa_propria, ""),
                      );
                      setFieldValue(
                        "tem_recebido_factura",
                        tasks.verificarValor(client.tem_recebido_factura, ""),
                      );
                      setFieldValue(
                        "agua_sai_regularidade",
                        tasks.verificarValor(client.agua_sai_regularidade, ""),
                      );
                      setFieldValue(
                        "agua_sai_regularidade_desc",
                        tasks.verificarValor(
                          client.agua_sai_regularidade_desc,
                          "",
                        ),
                      );
                      setFieldValue(
                        "estado_cavalete",
                        tasks.verificarValor(client.estado_cavalete, ""),
                      );
                      setFieldValue(
                        "cavalete_tem_fugas",
                        tasks.verificarValor(client.cavalete_tem_fugas, ""),
                      );
                      setFieldValue(
                        "estado_do_contador",
                        tasks.verificarValor(client.estado_do_contador, ""),
                      );
                      setFieldValue(
                        "diametro_contador",
                        tasks.verificarValor(client.diametro_contador, ""),
                      );
                      setFieldValue(
                        "regularidade_leitura",
                        tasks.verificarValor(client.regularidade_leitura, ""),
                      );
                      setFieldValue(
                        "avaliacao_adrmm",
                        tasks.verificarValor(client.avaliacao_adrmm, ""),
                      );
                      setFieldValue(
                        "numero_contador",
                        tasks.verificarValor(client.numero_contador, ""),
                      );
                      setFieldValue(
                        "leitura_actual",
                        tasks.verificarValor(client.leitura_actual, ""),
                      );
                      setFieldValue(
                        "data_registro",
                        tasks.verificarValor(client.data_registro, ""),
                      );
                      setFieldValue(
                        "situacao_entrevistado",
                        tasks.verificarValor(client.situacao_entrevistado, ""),
                      );
                      setFieldValue(
                        "tipo_actividade",
                        tasks.verificarValor(client.tipo_actividade, ""),
                      );
                      setFieldValue(
                        "fonte_agua",
                        tasks.verificarValor(client.fonte_agua, ""),
                      );
                      setFieldValue(
                        "fonte_agua_desc",
                        tasks.verificarValor(client.fonte_agua_desc, ""),
                      );
                      setFieldValue(
                        "suspeita_ilegal",
                        tasks.verificarValor(client.suspeita_ilegal, ""),
                      );
                      setFieldValue(
                        "foto_contador",
                        tasks.verificarValor(client?.foto_contador, ""),
                      );
                      setFieldValue(
                        "foto_residencia",
                        tasks.verificarValor(client?.foto_residencia, ""),
                      );
                      setFieldValue(
                        "foto_balisa",
                        tasks.verificarValor(client?.foto_balisa, ""),
                      );
                      setFieldValue(
                        "latitude",
                        tasks.verificarValor(client.latitude, ""),
                      );
                      setFieldValue(
                        "longitude",
                        tasks.verificarValor(client.longitude, ""),
                      );
                      setFieldValue(
                        "observacao",
                        tasks.verificarValor(client.observacao, ""),
                      );
                      setFieldValue(
                        "sem_adrmm_porque",
                        tasks.verificarValor(client.sem_adrmm_porque, ""),
                      );
                      setFieldValue(
                        "senhorio_nome",
                        tasks.verificarValor(client.senhorio_nome, ""),
                      );
                      setFieldValue(
                        "senhorio_contacto1",
                        tasks.verificarValor(client.senhorio_contacto1, ""),
                      );
                      setFieldValue(
                        "senhorio_contacto2",
                        tasks.verificarValor(client.senhorio_contacto2, ""),
                      );
                      setFieldValue(
                        "interessado_na_adrmm",
                        tasks.verificarValor(client.interessado_na_adrmm, ""),
                      );
                      setFieldValue(
                        "tipo_registo",
                        tasks.verificarValor(client.tipo_registo, ""),
                      );
                      setFieldValue(
                        "id_registrador",
                        tasks.verificarValor(client.id_registrador, ""),
                      );
                      setFieldValue(
                        "parceiro_negocio",
                        tasks.verificarValor(client.parceiro_negocio, ""),
                      );
                      setFieldValue(
                        "conta_contrato",
                        tasks.verificarValor(client.conta_contrato, ""),
                      );
                      setFieldValue(
                        "instalacao",
                        tasks.verificarValor(client.instalacao, ""),
                      );
                      setFieldValue(
                        "unidade_leitura",
                        tasks.verificarValor(client.unidade_leitura, ""),
                      );
                      setFieldValue(
                        "zona",
                        tasks.verificarValor(client.zona, ""),
                      );
                      setFieldValue(
                        "rua",
                        tasks.verificarValor(client.rua, ""),
                      );
                      setFieldValue(
                        "tipo_instalacao",
                        tasks.verificarValor(client.tipo_instalacao, ""),
                      );
                    }}
                    error={(error) => {
                      console.log(error);
                    }}
                  />
                  <div className="flex flex-1 flex-col items-center">
                    <div className=" flex px-[30px] flex-row w-full justify-center md:max-w-[600px] xl:max-w-[800px]">
                      <UpdateClient
                        isLoading={isLoading}
                        viewLog={viewLog2}
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        onClick={() => {
                          console.log("try submit");
                          handleSubmit();
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Actualizar;

const HelperFindClient = ({
  id,
  success,
  error,
}: {
  id: number;
  success?: (data: any) => void;
  error?: (error: any) => void;
}) => {
  useEffect(() => {
    load();
  }, []);

  const load = () => {
    api.get(
      {
        path: `/api/cliente/info?id=${id}`,
      },
      (data) => {
        success?.(data);
      },
      (errors) => {
        console.log(errors);
      },
    );
  };

  return <></>;
};
