import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Formik } from "formik";
import CosButton from "../../components/CosButton";
import CosTextField from "../../components/CosTextFiel";
import HeaderForm from "../../components/header-form";
import MyAlert from "../../components/my-alter";
import { User, UserType } from "../../types/usuario";
import schemas from "../../utils/schemas";
import AddUserViewModel from "../../viewmodel/usuarios/add";
import useResourcesZus from "../../zustand/resources-zus";
const AddUsuario = () => {
  const { bairros, areas } = useResourcesZus();

  const dateB = new Date("2000-01-01");

  const { user, isLoading, setNewLog, viewLog, submitAddAccount } =
    AddUserViewModel();

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="py-[20px] pt-[40px]">
          <HeaderForm title="Novo Utilizador" />
        </div>
        <Formik
          initialValues={{
            nome: "",
            email: "",
            telefone: "",
            telefone2: "",
            numero_bi: "",
            area_operacional: "",
            bairro: "",
            data_nascimento: `${dateB.getFullYear()}-${dateB.getMonth() + 1}-${dateB.getDate()}`,
            endereco: "",
            role: "",
            status: "Activo",
          }}
          validationSchema={schemas.usuario.updateMyInfo}
          onSubmit={(values) => {
            submitAddAccount(values as User);
          }}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <div className="flex flex-1 flex-col items-center gap-[15px] ">
                <div className="w-full flex flex-1 flex-col items-center gap-[15px] px-[30px] pb-[40px] tablet:pl-[40px] tablet:max-w-[650px]">
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Nome completo *"
                    autoComplete="off"
                    size="small"
                    placeholder="Nome Completo *"
                    errorMessage={errors.nome}
                    value={values.nome}
                    field="nome"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Email *"
                    size="small"
                    placeholder="Email *"
                    errorMessage={errors.email}
                    value={values.email}
                    field="email"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Telefone *"
                    size="small"
                    autoComplete="off"
                    placeholder="Telefone *"
                    errorMessage={errors.telefone}
                    value={values.telefone}
                    field="telefone"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Telefone Alternativo"
                    size="small"
                    autoComplete="off"
                    placeholder="Telefone Alternativo"
                    errorMessage={errors.telefone2}
                    value={values.telefone2}
                    field="telefone2"
                    setFieldValue={setFieldValue}
                  />
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="contracto_drop">
                      Tipo de acesso *
                    </InputLabel>
                    <Select
                      labelId="contracto_drop"
                      value={values.role}
                      label="Tipo de acesso *"
                      onChange={(event) => {
                        setFieldValue("role", event.target.value);
                      }}
                    >
                      <MenuItem value={UserType.Administrador}>
                        {UserType.Administrador}
                      </MenuItem>
                      <MenuItem value={UserType.Brigadista}>
                        {UserType.Brigadista}
                      </MenuItem>
                      <MenuItem value={UserType["Linha do Cliente"]}>
                        {UserType["Linha do Cliente"]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {values.role === UserType.Brigadista && (
                    <>
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-label">
                          Área Operacional *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.area_operacional}
                          label="Área Operacional *"
                          onChange={(event) => {
                            setFieldValue?.(
                              "area_operacional",
                              event.target.value,
                            );
                          }}
                        >
                          {areas.map((item) => {
                            return (
                              <MenuItem value={item.area_operacional}>
                                {item.area_operacional}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <FormControl size="small" fullWidth variant="outlined">
                        <InputLabel id="bairro_drop">Bairro *</InputLabel>
                        <Select
                          labelId="bairro_drop"
                          value={values.bairro}
                          label="Bairro *"
                          onChange={(event) => {
                            setFieldValue?.("bairro", event.target.value);
                          }}
                        >
                          {bairros.map((item: any) => {
                            return (
                              <MenuItem value={item.bairro}>
                                {item.bairro}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </>
                  )}
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Numero do B.I *"
                    size="small"
                    placeholder="Numero do B.I *"
                    errorMessage={errors.numero_bi}
                    value={values.numero_bi}
                    autoComplete="off"
                    field="numero_bi"
                    setFieldValue={setFieldValue}
                  />
                  <div className="flex flex-1 w-full flex-col">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateField", "DateField"]}>
                        <DateField
                          fullWidth
                          size="small"
                          label="Data de nascimento *"
                          value={dayjs(values.data_nascimento)}
                          format="DD-MM-YYYY"
                          onChange={(event) => {
                            setFieldValue(
                              "data_nascimento",
                              event?.format("YYYY-MM-DD"),
                            );
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Endereço *"
                    size="small"
                    placeholder="Endereço *"
                    autoComplete="off"
                    errorMessage={errors.endereco}
                    value={values.endereco}
                    field="endereco"
                    setFieldValue={setFieldValue}
                  />
                  {viewLog.show && (
                    <div className="w-full flex flex-1 justify-center items-center">
                      <MyAlert type={viewLog.type} message={viewLog.message} />
                    </div>
                  )}
                  <CosButton
                    isLoading={isLoading.value}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (Object.values(errors).length > 0) {
                        setNewLog({
                          type: "WARNING",
                          message:
                            "Cerifique que todos os campos obrigátrios estão preenchidos e correctamente.",
                        });
                        return;
                      }
                      handleSubmit();
                    }}
                  >
                    REGISTRAR
                  </CosButton>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddUsuario;
