import MyLocaction from "../maps/my-locaction";
// src/CustomSelect.js
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import { ConstantsClientForm } from "../../constants/cliente-form";
import { ViewLogProps } from "../../hooks/use-view-log";
import CosButton from "../CosButton";
import CosTextField from "../CosTextFiel";
import MyAlert from "../my-alter";
import { ClientFormProps } from "./client-form";

interface PotencialProps extends ClientFormProps {
  onGetPhoto?: (foto: "F1" | "F2" | "FB", file: any) => void;
  isLoading?: boolean;
  viewLog?: ViewLogProps;
  onClick: () => void;
}

const Ilegal = ({
  setFieldValue,
  values,
  errors,
  onGetPhoto,
  isLoading = false,
  viewLog,
  onClick,
}: PotencialProps) => {
  const [showFoto, setShowFoto] = useState(false);
  useEffect(() => {
    if (viewLog?.show && viewLog.type === "SUCCESS") {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }, [viewLog]);

  const handleFoto1 = (event: any) => {
    let file = event.target.files[0];
    if (file) {
      onGetPhoto?.("F1", file);
      const imageUrl = URL.createObjectURL(file);
      file.path = imageUrl;
      setFieldValue?.("foto_contador", imageUrl);
    }
  };

  const handleFoto2 = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      onGetPhoto?.("F2", file);
      const imageUrl = URL.createObjectURL(file);
      file.path = imageUrl;
      setFieldValue?.("foto_residencia", imageUrl);
    }
  };

  const handleFotoBalisa = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      onGetPhoto?.("FB", file);
      const imageUrl = URL.createObjectURL(file);
      file.path = imageUrl;
      setFieldValue?.("foto_balisa", imageUrl);
    }
  };

  return (
    <div className="w-full flex flex-col gap-[15px]">
      <div>
        <p className=" text-[18px] font-bold text-center mt-[10px]">
          Informações do Potencial Cliente
        </p>
      </div>
      <CosTextField
        fullWidth
        variant="outlined"
        label="Nome Completo *"
        size="small"
        placeholder="Nome Completo *"
        value={values.nome}
        errorMessage={errors.nome}
        field="nome"
        setFieldValue={setFieldValue}
      />
      <CosTextField
        fullWidth
        variant="outlined"
        label="Contacto Telefone 1 *"
        size="small"
        placeholder="Contacto Telefone 1 *"
        value={values.contacto1}
        errorMessage={errors.contacto1}
        field="contacto1"
        setFieldValue={setFieldValue}
      />
      <CosTextField
        fullWidth
        variant="outlined"
        label="Contacto Telefone 2"
        size="small"
        placeholder="Contacto Telefone 2"
        value={values.contacto2}
        errorMessage={errors.contacto2}
        field="contacto2"
        setFieldValue={setFieldValue}
      />
      <CosTextField
        fullWidth
        variant="outlined"
        label="Contacto Telefone 3"
        size="small"
        placeholder="Contacto Telefone 3"
        value={values.contacto3}
        errorMessage={errors.contacto3}
        field="contacto3"
        setFieldValue={setFieldValue}
      />
      <CosTextField
        fullWidth
        variant="outlined"
        label="Contacto Telefone 4"
        size="small"
        placeholder="Contacto Telefone 4"
        value={values.contacto4}
        errorMessage={errors.contacto4}
        field="contacto4"
        setFieldValue={setFieldValue}
      />
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="cat_drop">Tipo de Actividade *</InputLabel>
        <Select
          labelId="cat_drop"
          value={values.tipo_actividade}
          label="Tipo de actividade *"
          onChange={(event) => {
            setFieldValue?.("tipo_actividade", event.target.value);
          }}
        >
          {ConstantsClientForm.categoriaCliente.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl>
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="catw_drop">Qual é a fonte de água? *</InputLabel>
        <Select
          labelId="catw_drop"
          value={values.fonte_agua}
          label="Qua é a fonte de água? *"
          onChange={(event) => {
            setFieldValue?.("fonte_agua", event.target.value);
          }}
        >
          <MenuItem value={"Poço"}>{"Poço"}</MenuItem>
          <MenuItem value={"Nascente"}>{"Nascente"}</MenuItem>
          <MenuItem value={"Rio"}>{"Rio"}</MenuItem>
          <MenuItem value={"Lago"}>{"Lago"}</MenuItem>
          <MenuItem value={"Lagoa"}>{"Lagoa"}</MenuItem>
          <MenuItem value={"Água da Chuva"}>{"Água da Chuva"}</MenuItem>
          <MenuItem value={"Tanque Movél ou Camião"}>
            {"Tanque Movél ou Camião"}
          </MenuItem>
          <MenuItem value={"Água Engarrafada"}>{"Água Engarrafada"}</MenuItem>

          <MenuItem value={"Casa do Vizinho"}>{"Casa do Vizinho"}</MenuItem>
          <MenuItem value={"Fontenário ou Torneira Público"}>
            {"Fontenário ou Torneira Público"}
          </MenuItem>
          <MenuItem value={"Outras"}>{"Outras"}</MenuItem>
          <MenuItem value={"Fornecimento Privado"}>
            {"Fornecimento Privado"}
          </MenuItem>
        </Select>
      </FormControl>
      {(values.fonte_agua === "Outras" ||
        values.fonte_agua === "Fornecimento Privado") && (
        <CosTextField
          label={
            values.fonte_agua === "Outras" ? "Especifique" : "Nome da empresa"
          }
          variant="outlined"
          fullWidth
          value={values.fonte_agua_desc}
          errorMessage={errors?.fonte_agua_desc}
          field="fonte_agua_desc"
          setFieldValue={setFieldValue}
        />
      )}
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="possi_drop">
          Porque não tem a ligação de água da ADRMM? *
        </InputLabel>
        <Select
          labelId="possi_drop"
          value={values.sem_adrmm_porque}
          label="Porque não tem a ligação de água da ADRMM *"
          onChange={(event) => {
            setFieldValue?.("sem_adrmm_porque", event.target.value);
          }}
        >
          <MenuItem value={"Não tenho acesso a rede de abastecimento de água"}>
            {"Não tenho acesso a rede de abastecimento de água"}
          </MenuItem>
          <MenuItem value={"Não tenho condições de pagar valor de contrato"}>
            {"Não tenho condições de pagar valor de contrato"}
          </MenuItem>
          <MenuItem value={"Estou a arrendar"}>{"Estou a arrendar"}</MenuItem>
          <MenuItem value={"Aproximou-se a empresa e não teve resposta"}>
            {"Aproximou-se a empresa e não teve resposta"}
          </MenuItem>
        </Select>
      </FormControl>
      {values.sem_adrmm_porque === "Estou a arrendar" && (
        <>
          <CosTextField
            label="Nome do Senhório"
            multiline
            rows={2}
            variant="outlined"
            fullWidth
            value={values.senhorio_nome}
            errorMessage={errors?.senhorio_nome}
            field="senhorio_nome"
            setFieldValue={setFieldValue}
          />
          <CosTextField
            label="Senhório Contacto 2"
            variant="outlined"
            fullWidth
            value={values.senhorio_contacto1}
            errorMessage={errors?.senhorio_contacto1}
            field="senhorio_contacto1"
            setFieldValue={setFieldValue}
          />
          <CosTextField
            label="Senhório Contacto 2"
            variant="outlined"
            fullWidth
            value={values.senhorio_contacto2}
            errorMessage={errors?.senhorio_contacto2}
            field="senhorio_contacto2"
            setFieldValue={setFieldValue}
          />
        </>
      )}
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="possi_drop">
          Está interessado em ter a água da ADRMM? *
        </InputLabel>
        <Select
          labelId="possi_drop"
          value={values.interessado_na_adrmm}
          label="Está interessado em ter a água da ADRMM? *"
          onChange={(event) => {
            setFieldValue?.("interessado_na_adrmm", event.target.value);
          }}
        >
          <MenuItem value={"Sim"}>{"Sim"}</MenuItem>
          <MenuItem value={"Não"}>{"Não"}</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" fullWidth variant="outlined">
        <InputLabel id="possi_drop">
          Tem suspeita de consumo ilegal? *
        </InputLabel>
        <Select
          labelId="possi_drop"
          value={values.suspeita_ilegal}
          label="Tem suspeita de consumo ilegal? *"
          onChange={(event) => {
            setFieldValue?.("suspeita_ilegal", event.target.value);
            if (event.target.value === "Sim") {
              setShowFoto(true);
            } else {
              setShowFoto(false);
            }
          }}
        >
          <MenuItem value={"Sim"}>{"Sim"}</MenuItem>
          <MenuItem value={"Não"}>{"Não"}</MenuItem>
        </Select>
      </FormControl>
      {showFoto && (
        <>
          <p className=" text-[18px] font-bold text-center mt-[10px]">Fotos</p>
          <div className="flex flex-1 flex-row w-full">
            <div className="flex flex-1 flex-col items-center gap-[10px]">
              <Input
                style={{ display: "none" }}
                id="image-upload1"
                type="file"
                onChange={handleFoto1}
              />
              <div style={{ marginTop: "20px" }}>
                <img
                  className="w-[90px] h-[90px] rounded-[7px] overflow-hidden border-[3px]"
                  src={values.foto_contador}
                  alt="Selected"
                  width="100%"
                  height="auto"
                />
              </div>
              <label htmlFor="image-upload1">
                <Button variant="contained" component="span">
                  CONTADOR
                </Button>
              </label>
            </div>

            <div className="flex flex-1 flex-col items-center gap-[10px]">
              <Input
                style={{ display: "none" }}
                id="image-upload3"
                type="file"
                onChange={handleFotoBalisa}
              />
              <div style={{ marginTop: "20px" }}>
                <img
                  className="w-[90px] h-[90px] rounded-[7px] overflow-hidden border-[3px]"
                  src={values.foto_balisa}
                  alt="Selected"
                  width="100%"
                  height="auto"
                />
              </div>
              <label htmlFor="image-upload3">
                <Button variant="contained" component="span">
                  BALIZA
                </Button>
              </label>
            </div>
            <div className="flex flex-1 flex-col items-center gap-[10px]">
              <Input
                style={{ display: "none" }}
                id="image-upload2"
                type="file"
                onChange={handleFoto2}
              />
              <div style={{ marginTop: "20px" }}>
                <img
                  className="w-[90px] h-[90px] rounded-[7px] overflow-hidden border-[3px]"
                  src={values.foto_residencia}
                  alt="Selected"
                  width="100%"
                  height="auto"
                />
              </div>
              <label htmlFor="image-upload2">
                <Button variant="contained" component="span">
                  RESIDÊNCIA
                </Button>
              </label>
            </div>
          </div>
        </>
      )}
      <div>
        <p className=" text-[18px] font-bold text-center mt-[10px]">
          Geolocalização
        </p>
      </div>
      <div>
        <MyLocaction
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
        />
      </div>
      <div>
        <p className=" text-[18px] font-bold text-center mt-[10px]">
          Observações
        </p>
      </div>
      <CosTextField
        label="Mais informações"
        multiline
        rows={2}
        variant="outlined"
        fullWidth
        value={values.observacao}
        errorMessage={errors?.observacao}
        field="observacao"
        setFieldValue={setFieldValue}
      />
      {viewLog?.show && (
        <MyAlert type={viewLog.type} message={viewLog.message} />
      )}
      <div className="flex flex-1">
        <CosButton
          onClick={onClick}
          isLoading={isLoading}
          variant="contained"
          fullWidth
        >
          SALVAR
        </CosButton>
      </div>
    </div>
  );
};

export default Ilegal;
