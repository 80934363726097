import { TextFieldProps } from "@mui/material";
import { useState } from "react";
import "./CosTextFiel.css";
type CosTextFieldProps = TextFieldProps & {
  setFieldValue?: (field: string, value: string) => void;
  errorMessage?: String | undefined;
  field?: string;
};

const CosTextField = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  errorMessage,
  setFieldValue,
  field,
  ...props
}: CosTextFieldProps) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <div className={`custom-input-container ${isFocused ? "focused" : ""}`}>
      {label && <label className="custom-input-label">{label}</label>}
      <input
        inputMode="password"
        {...(props as any)}
        className={`custom-input-field ${error ? "error" : ""}`}
        value={value as string}
        onChange={(event) => {
          setFieldValue?.(field ?? "", event.target.value);
        }}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {errorMessage !== undefined &&
        errorMessage !== null &&
        errorMessage !== "" &&
        value !== undefined &&
        value !== null &&
        value !== "" && (
          <span className="text-[#ed1c39] text-[13px]">{errorMessage}</span>
        )}
    </div>
  );
};

export default CosTextField;
