import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import api from "./api";
import "./App.css";
import LeftNav from "./components/menu/left-menu";
import NavBar from "./components/menu/nav-bar";
import { UserType } from "./types/usuario";
import useAppInfo from "./zustand/app-info-zus";
import useResourcesZus from "./zustand/resources-zus";

function App() {
  document.title = "ADRMM";
  const navigate = useNavigate();
  const { user } = useAppInfo();

  const { bairros, areas, setBairros, setAreas } = useResourcesZus();

  console.log("Zus");

  useEffect(() => {
    if (bairros.length === 0) {
      loadBairros();
    }
    if (areas.length === 0) {
      loadAreas();
    }
  });

  const loadBairros = () => {
    api.get(
      {
        path: "/api/resources/bairros",
      },
      (data) => {
        setBairros(data);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  const loadAreas = () => {
    api.get(
      {
        path: "/api/resources/areas",
      },
      (data) => {
        setAreas(data);
      },
      (error) => {
        console.log(error);
      },
    );
  };

  return user !== undefined ? (
    user.role === UserType.Brigadista ? (
      <Navigate to={"/geral/nav/lista-clientes"} />
    ) : (
      <div className="flex flex-row">
        <LeftNav />
        <div className="w-full">
          <NavBar />
          <Outlet />
        </div>
      </div>
    )
  ) : (
    <Navigate to={"/authentication"} />
  );
}

export default App;
