import { Button, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useState } from "react";
import api from "../../api";
import CosButton from "../../components/CosButton";
import CosTextField from "../../components/CosTextFiel";
import MyAlert from "../../components/my-alter";
import { User, UserType } from "../../types/usuario";
import schemas from "../../utils/schemas";
import PerfilViewModel from "../../viewmodel/usuarios/perfil";

const Perfil = () => {
  const {
    user,
    setUser,
    isLoading,
    setNewLog,
    viewLog,
    submitUpdateAccount,
    handleLogout,
  } = PerfilViewModel();

  const dateB = new Date(user?.data_nascimento ?? "");

  const [senha, setSenha] = useState("");
  const submitT = () => {
    api.post(
      {
        path: "/api/user/reset-password",
        data: {
          email: user?.email,
          hash_confirmation: "30941513franciscotamelle@gmail.com",
          senha: senha,
        },
      },
      (data) => {
        setUser(data);
        setNewLog({
          type: "SUCCESS",
          message: "Senha actualizada com sucesso.",
        });
      },
      (error) => {
        console.log(error);
        setNewLog({
          type: "WARNING",
          message: "Erro ao actualizar a senha.",
        });
      },
    );
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      {}
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="py-[20px] pt-[40px]">
          <div className="w-full flex flex-col justify-center items-center">
            <p className=" text-[23px] font-bold text-black-800 text-center tablet:text-left">
              {"Minha Conta"}
            </p>
            <p className=" text-[14px] text-center tablet:text-left">
              {
                "Ao actualizar os dados deve clicar em confirmar para salvá-los."
              }
            </p>
          </div>
        </div>
        <Formik
          initialValues={{
            nome: user?.nome,
            email: user?.email,
            telefone: user?.telefone,
            telefone2: user?.telefone2,
            numero_bi: user?.numero_bi,
            area_operacional: user?.area_operacional,
            bairro: user?.bairro,
            data_nascimento: `${dateB.getFullYear()}-${dateB.getMonth() + 1}-${dateB.getDate()}`,
            endereco: user?.endereco,
            role: user?.role,
            status: user?.status,
          }}
          validationSchema={schemas.usuario.updateMyInfo}
          onSubmit={(values) => {
            submitUpdateAccount(values as User);
          }}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <div className="flex flex-1 flex-col items-center gap-[15px] ">
                <div className="w-full flex flex-1 flex-col items-center gap-[15px] px-[30px] pb-[40px] tablet:pl-[40px] tablet:max-w-[650px]">
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Nome completo *"
                    autoComplete="off"
                    size="small"
                    placeholder="Nome Completo *"
                    errorMessage={errors.nome}
                    value={values.nome}
                    field="nome"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Email *"
                    size="small"
                    placeholder="Email *"
                    errorMessage={errors.email}
                    value={values.email}
                    field="email"
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Telefone *"
                    size="small"
                    autoComplete="off"
                    placeholder="Telefone *"
                    errorMessage={errors.telefone}
                    value={values.telefone}
                    field="telefone"
                    setFieldValue={setFieldValue}
                  />
                  {user?.role === UserType.Brigadista && (
                    <>
                      <CosTextField
                        fullWidth
                        variant="outlined"
                        label="Area Operacional"
                        size="small"
                        placeholder="Area Operacional"
                        value={values.area_operacional}
                        field="email"
                      />
                      <CosTextField
                        fullWidth
                        variant="outlined"
                        label="Bairro"
                        size="small"
                        placeholder="Bairro"
                        value={values.bairro}
                        field="email"
                      />
                    </>
                  )}
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Telefone Alternativo"
                    size="small"
                    autoComplete="off"
                    placeholder="Telefone Alternativo"
                    errorMessage={errors.telefone2}
                    value={values.telefone2}
                    field="telefone2"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    fullWidth
                    disabled
                    value={values.role}
                    variant="outlined"
                    label="Tipo de conta"
                    size="small"
                    placeholder="Tipo de acesso"
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Numero do B.I *"
                    size="small"
                    placeholder="Numero do B.I *"
                    errorMessage={errors.numero_bi}
                    value={values.numero_bi}
                    autoComplete="off"
                    field="numero_bi"
                    setFieldValue={setFieldValue}
                  />
                  <div className="flex flex-1 w-full flex-col">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateField", "DateField"]}>
                        <DateField
                          fullWidth
                          size="small"
                          label="Data de nascimento *"
                          value={dayjs(values.data_nascimento)}
                          format="DD-MM-YYYY"
                          onChange={(event) => {
                            setFieldValue(
                              "data_nascimento",
                              event?.format("YYYY-MM-DD"),
                            );
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Endereço *"
                    size="small"
                    placeholder="Endereço *"
                    autoComplete="off"
                    errorMessage={errors.endereco}
                    value={values.endereco}
                    field="endereco"
                    setFieldValue={setFieldValue}
                  />
                  <div className="flex flex-1 justify-start text-[15px]">
                    <span className=" font-bold">Mudar a Senha</span>
                  </div>
                  <div className="w-full flex flex-row flex-1 gap-[20px]">
                    <div className="flex flex-1 w-full">
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Nova senha"
                        size="small"
                        value={senha}
                        type="password"
                        placeholder="Nova senha"
                        onChange={(event) => {
                          setSenha(event.target.value);
                        }}
                      />
                    </div>
                    <div>
                      <Button
                        onClick={submitT}
                        style={{ height: 42 }}
                        size="small"
                        variant="contained"
                        fullWidth
                      >
                        MUDAR SENHA
                      </Button>
                    </div>
                  </div>
                  {viewLog.show && (
                    <div className="w-full flex flex-1 justify-center items-center">
                      <MyAlert type={viewLog.type} message={viewLog.message} />
                    </div>
                  )}

                  <CosButton
                    isLoading={isLoading.value}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (Object.values(errors).length > 0) {
                        setNewLog({
                          type: "WARNING",
                          message:
                            "Cerifique que todos os campos obrigátrios estão preenchidos e correctamente.",
                        });
                        return;
                      }
                      handleSubmit();
                    }}
                  >
                    ACTUALIZAR
                  </CosButton>
                  <div className="w-full mt-[20pxs]">
                    <Button
                      onClick={handleLogout}
                      variant="contained"
                      fullWidth
                      style={{ backgroundColor: "#ed1c39" }}
                    >
                      SAIR DO SISTEMA
                    </Button>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Perfil;
