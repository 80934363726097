import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import SessionStorage from "../../constants/session-storage";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import useAppInfo from "../../zustand/app-info-zus";

const ForgotNewPasswordViewModel = () => {
  const { userC, setUser, setUserC } = useAppInfo();

  console.log(userC);
  const isLoading = useBetterState<boolean>(false);
  const navigate = useNavigate();

  const { viewLog, setNewLog } = useViewLog();

  const handleNewPassword = async (senha: string, senhaConfirmar: string) => {
    if (senha !== senhaConfirmar) {
      setNewLog({
        type: "WARNING",
        message: "As senhas não combinam.",
      });
      return;
    }
    isLoading.value = true;

    api.post(
      {
        path: "api/user/reset-password",
        data: {
          email: userC?.email,
          hash_confirmation: userC?.hash_confirmation,
          senha: senha,
        },
        params: {
          type: "RECOVER",
        },
      },
      async (data: any) => {
        isLoading.value = false;
        sessionStorage.setItem(SessionStorage.AUTH_TOKEN, data.token);
        setUser(data);
        setUserC(null);
        navigate("/");
      },
      (error) => {
        isLoading.value = false;
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  return {
    isLoading,
    viewLog,
    handleNewPassword,
  };
};

export default ForgotNewPasswordViewModel;
