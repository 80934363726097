import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import useAppInfo from "../../zustand/app-info-zus";

const ForgetEmailViewModel = () => {
  const { setUserC } = useAppInfo();
  const isLoading = useBetterState<boolean>(false);
  const navigate = useNavigate();

  const { viewLog, setNewLog } = useViewLog();

  const submitFindAccount = (email: string) => {
    isLoading.value = true;

    api.post(
      {
        path: "/api/user/recover",
        data: {
          email: email,
        },
      },
      (data) => {
        isLoading.value = false;
        setUserC(data);
        navigate("/authentication/forgot-confirmation");
      },
      (error) => {
        isLoading.value = false;
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else if (error.status === HTTP_STATUS.NOT_FOUND) {
          setNewLog({
            type: "WARNING",
            message: "Não existe conta associada a este email.",
          });
        } else if (error.status === HTTP_STATUS.USER_BLOCKED) {
          setNewLog({
            type: "WARNING",
            message: "Esta conta se encontra bloqueada.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  return {
    isLoading,
    viewLog,
    submitFindAccount,
  };
};

export default ForgetEmailViewModel;
