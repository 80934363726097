import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api";
import LabelInfo from "../../components/label-info";
import tasks from "../../utils/tasks";

const BrigadistaPerfil = () => {
  const { id } = useParams();
  const [user, setUserFound] = useState<any | undefined>(undefined);

  useEffect(() => {
    loadBrigadista();
  });

  const loadBrigadista = () => {
    api.get(
      {
        path: "/api/user/find",
        params: {
          id: id,
        },
      },
      (data) => {
        setUserFound(data);
      },
      (error) => {},
    );
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      {}
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="py-[20px] pt-[40px]">
          <div className="w-full flex flex-col justify-center items-center">
            <p className=" text-[23px] font-bold text-black-800 text-center tablet:text-left">
              {"Perfil do Brigadista"}
            </p>
            <p className=" text-[14px] text-center tablet:text-left">
              Numero total de entrevista efectuadas :{" "}
              {tasks.verificarValor(user?.total, " - ")}
            </p>
          </div>
        </div>

        <div className="flex flex-1 flex-col items-center gap-[15px] ">
          <div className="w-full flex flex-1 flex-col items-center gap-[15px] px-[30px] pb-[40px] tablet:pl-[40px] tablet:max-w-[650px]">
            <LabelInfo
              title="Nome Completo"
              value={tasks.verificarValor(user?.nome, "Sem informação")}
            />
            <LabelInfo
              title="Email"
              value={tasks.verificarValor(user?.email, "Sem informação")}
            />
            <LabelInfo
              title="Telefone"
              value={tasks.verificarValor(user?.telefone, "Sem informação")}
            />
            <LabelInfo
              title="Telefone 2"
              value={tasks.verificarValor(user?.telefone2, "Sem informação")}
            />
            <LabelInfo
              title="Tipo de Conta"
              value={tasks.verificarValor(user?.role, "Sem informação")}
            />
            <LabelInfo
              title="Area Operacional"
              value={tasks.verificarValor(
                user?.area_operacional,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Bairro"
              value={tasks.verificarValor(user?.bairro, "Sem informação")}
            />
            <LabelInfo
              title="Data de Nascimento"
              value={tasks.verificarValor(
                user?.data_nascimento,
                "Sem informação",
              )}
            />
            <LabelInfo
              title="Numero de B.I"
              value={tasks.verificarValor(user?.numero_bi, "Sem informação")}
            />
            <LabelInfo
              title="Endereço"
              value={tasks.verificarValor(user?.endereco, "Sem informação")}
            />
            <LabelInfo
              title="Estado"
              value={tasks.verificarValor(user?.status, "Sem informação")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrigadistaPerfil;
