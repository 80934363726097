import { Button } from "@mui/material";
import { createContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo, Menu, User } from "../../assets/svg";
import { UserType } from "../../types/usuario";
import useAppInfo from "../../zustand/app-info-zus";
import logo2 from "./../../assets/img/logo.png";
import MenuItem from "./menu-item";
import "./nav-bar.css";
import SubMenu from "./submenu";

interface ContextNavBarProps {
  showMenu: boolean;
  closeMenu?: () => void;
}

export const ContextNavBar = createContext<ContextNavBarProps>({
  showMenu: false,
});

const NavBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { user, setLogout } = useAppInfo();
  const navigate = useNavigate();

  const handleLogout = () => {
    setLogout();
    sessionStorage.removeItem("chaveDoItem");
    navigate("/authentication");
  };

  const closeMenu = () => {
    setShowMenu(false);
  };
  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className="rounded-[5px] flex flex-row px-[20px] py-[10px] mt-[13px] justify-between elevated">
        {user?.role === UserType.Brigadista ? (
          <Link to={"/"}>
            <div className="px-[20px] flex flex-row justify-center items-center gap-[10px]">
              <img src={logo2} className="h-[30px] w-[25px]" />
              <p className=" text-black-700 font-bold text-center text-[25px]">
                ADRMM
              </p>
            </div>
          </Link>
        ) : (
          <div className=" flex flex-row items-center tablet:hidden">
            <button
              onClick={() => {
                setShowMenu(!showMenu);
              }}
            >
              <Menu width={35} height={35} />
            </button>
            <img src={Logo} className=" ml-4 w-[25px] h-[30px] sm:hidden" />
          </div>
        )}
        <div />
        <Link to={"/geral/nav/minha-conta"}>
          <div className="bg-blue-900 flex flex-row px-[20px] py-[10px] rounded-[5px] items-center justify-center gap-[4px]">
            <User width={16} height={20} />
            <p className="text-blue-100">{user?.nome}</p>
          </div>
        </Link>
      </div>
      {showMenu && (
        <ContextNavBar.Provider value={{ showMenu, closeMenu }}>
          <div
            className=" py-[20px] w-[270px] bg-[#fff] rounded-[7px] mb-[10px] absolute z-30 border-[1px] border-black-200 tablet:hidden"
            style={{ top: "100%" }}
          >
            <div className="px-[15px] flex flex-col gap-[10px]">
              <div className="flex flex-col gap-[10px]">
                <SubMenu label="Painel Geral">
                  <MenuItem label="Relátorio Geral" path="/" />
                </SubMenu>
                <SubMenu label="Clientes">
                  <MenuItem label="Registar" path="/gerais/add-client" />
                  <MenuItem label="Lista" path="/geral/nav/lista-clientes" />
                </SubMenu>
                <SubMenu label="Brigadistas">
                  <MenuItem label="Lista" path="/lista-brigadistas" />
                </SubMenu>
              </div>
              {user?.role !== UserType["Linha do Cliente"] && (
                <>
                  <div>
                    <p className="text-[17px] text-gray-400 mt-[15px] font-bold">
                      Relátorios
                    </p>
                  </div>
                  <div className="flex flex-col gap-[15px]">
                    <SubMenu label="Relátorio Geral">
                      <MenuItem label="Clientes" path="/admin/clientes" />
                      <MenuItem label="Brigadistas" path="/admin/brigadistas" />
                    </SubMenu>
                    <SubMenu label="Mapa Geral">
                      <MenuItem label="Ver Mapa" path="/admin/mapa-geral" />
                    </SubMenu>
                    <SubMenu label="Usuários">
                      <MenuItem label="Registrar" path="/admin/add-usuario" />
                      <MenuItem label="Lista" path="/admin/lista-usuario" />
                    </SubMenu>
                  </div>
                </>
              )}
              <div className="mt-[20px] w-full mb-[20px] bg-black-100">
                <Button
                  onClick={handleLogout}
                  variant="contained"
                  style={{ width: "100%", background: "#ed1c39" }}
                >
                  SAIR
                </Button>
              </div>
            </div>
          </div>
        </ContextNavBar.Provider>
      )}
    </div>
  );
};

export default NavBar;
