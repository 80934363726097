import { Logo } from "../assets/svg";

interface HeaderFormProps {
  title?: string;
  description?: string;
}

const HeaderForm = ({
  title = "Novo Entrevistado",
  description = "Os campos com (*) são de carácter obrigátorio",
}: HeaderFormProps) => {
  return (
    <div className="flex flex-1 flex-col  tablet:flex-row  justify-center items-center gap-[15px]">
      <img src={Logo} className="w-[28px]" />
      <div>
        <p className=" text-[23px] font-bold text-black-800 text-center tablet:text-left">
          {title}
        </p>
        <p className=" text-[14px] text-center tablet:text-left">
          {description}
        </p>
      </div>
    </div>
  );
};

export default HeaderForm;
