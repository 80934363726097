import React from "react";
import { createRoot } from "react-dom/client";
import {
  APIProvider,
  Map,
  MapCameraChangedEvent,
} from "@vis.gl/react-google-maps";
import { useState } from "react";
import { useEffect } from "react";
import ExampleMaps from "../examples/maps/showmap";
//

const Maps = () => {
  return (
    <div className="w-full h-[400px]">
      <ExampleMaps />
    </div>
  );
};

export default Maps;
