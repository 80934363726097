export const HTTP_STATUS = {
  CREATED: "CREATE",
  USER_EXISTS: "USER_EXISTS",
  USER_BLOCKED: "USER_BLOCKED",
  HASH_WROND: "HASH_WRONG",
  ACCESS_DENIED: "ACCESS_DENIED",
  SUCCESS: "SUCCESS",
  SERVER_ERROR: "SERVER_ERROR",
  BAD_REQUEST: "BAD_REQUEST",
  UNAUTHORIZED: "UNAUTHORIZED",
  NOT_FOUND: "NOT_FOUND",
  PAY_SUCCESS_INFO_COMPANIE_ERROR: "PAY_SUCCESS_INFO_COMPANIE_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
  UNEXPECTED_ERROR: "UNEXPECTED_ERROR",
};

interface HttpError {
  status: string;
  details: any;
  path: any;
}

export const httpResponse = (
  status: "SUCCESS" | "ERROR" | "PANICO",
  data: any,
) => {
  return {
    status: status,
    data: data,
  };
};

export type { HttpError };
