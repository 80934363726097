const ConstantsClientForm = {
  fonteDaAgua: [
    "ADRM",
    "Poço",
    "Torneira do vizinho",
    "Fontenário",
    "Camião-Cisterna",
    "Furo",
  ],
  categoriaCliente: ["Comercial", "Doméstico", "Industrial", "Público"],
  motivosParaNaoADRM: [
    "Não tem acesso a rede",
    "Não tem valor do contracto",
    "Casa alugada",
    "Já aproximou a AdrMM mas não teve resposta",
  ],
  fontePrincipalAgua: [
    "Não tem acesso a rede",
    "Não tem valor do contracto",
    "Casa alugada",
    "Já aproximou a AdrMM mas não teve resposta",
  ],
};

export enum EntrevistadoTipo {
  "CLIENTE" = "Cliente",
  "POTENCIAL" = "Potencial",
  "PRIVADO" = "Privado",
}

export { ConstantsClientForm };
