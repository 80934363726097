import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Circle } from "./../../assets/svg/circle.svg";
import { ContextNavBar } from "./nav-bar";

interface MenuItemProps {
  path?: string;
  label: string;
}

const MenuItem = ({ path = "not-defined", label }: MenuItemProps) => {
  const navigate = useNavigate();
  const { closeMenu } = useContext(ContextNavBar);

  return (
    <button
      onClick={() => {
        closeMenu?.();
        navigate(path);
      }}
    >
      <div className="flex flex-row gap-[15px] items-center hover:bg-gray-200 px-[10px] py-[5px] rounded-[5px]">
        <Circle width={8} height={8} />
        <p className=" text-[16px] text-gray-500">{label}</p>
      </div>
    </button>
  );
};

export default MenuItem;
