import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useBetterState } from "../../hooks/use-better-state";
import { UserType } from "../../types/usuario";
import useAppInfo from "../../zustand/app-info-zus";
import logo from "./../../assets/img/logo.png";
import "./left-menu.css";
import MenuItem from "./menu-item";
import SubMenu from "./submenu";

interface LeftNavProps {}

const LeftNav = () => {
  const showMenu = useBetterState(false);
  const { setLogout, user } = useAppInfo();
  const navigate = useNavigate();

  const handleLogout = () => {
    setLogout();
    sessionStorage.removeItem("chaveDoItem");
    navigate("/authentication");
  };

  return (
    <div
      className={`hidden w-[240px] bg-[#fff] menuContainer tablet:flex tablet:flex-col`}
    >
      <Link to={"/"}>
        <div className="px-[20px] flex flex-row justify-center py-[25px] items-center gap-[10px]">
          <img src={logo} className="h-[30px] w-[25px]" />
          <p className=" text-black-700 font-bold text-center text-[25px]">
            ADRMM
          </p>
        </div>
      </Link>
      <div className="px-[15px] flex flex-col gap-[10px]">
        <div className="flex flex-col gap-[10px]">
          <SubMenu label="Painel Geral">
            <MenuItem label="Relátorio Geral" path="/" />
          </SubMenu>
          <SubMenu label="Clientes">
            <MenuItem label="Registar" path="/gerais/add-client" />
            <MenuItem label="Lista" path="/geral/nav/lista-clientes" />
          </SubMenu>
          <SubMenu label="Brigadistas">
            <MenuItem label="Lista" path="/lista-brigadistas" />
          </SubMenu>
        </div>
        {user?.role !== UserType["Linha do Cliente"] && (
          <>
            <div>
              <p className="text-[17px] text-gray-400 mt-[15px] font-bold">
                Relátorios
              </p>
            </div>
            <div className="flex flex-col gap-[15px]">
              <SubMenu label="Relátorio Geral">
                <MenuItem label="Clientes" path="/admin/clientes" />
                <MenuItem label="Brigadistas" path="/admin/brigadistas" />
              </SubMenu>
              <SubMenu label="Mapa Geral">
                <MenuItem label="Ver Mapa" path="/admin/mapa-geral" />
              </SubMenu>
              <SubMenu label="Usuários">
                <MenuItem label="Registrar" path="/admin/add-usuario" />
                <MenuItem label="Lista" path="/admin/lista-usuario" />
              </SubMenu>
            </div>
          </>
        )}
        <div className="mt-[20px] w-full mb-[20px] bg-black-100">
          <Button
            onClick={handleLogout}
            variant="contained"
            style={{ width: "100%", background: "#ed1c39" }}
          >
            SAIR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LeftNav;
