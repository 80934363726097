import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import SessionStorage from "../../constants/session-storage";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import useAppInfo from "../../zustand/app-info-zus";

const SignInViewModel = () => {
  const { setUser } = useAppInfo();

  const isLoading = useBetterState<boolean>(false);
  const navigate = useNavigate();

  const { viewLog, setNewLog } = useViewLog();

  const submitLoginForm = (email: string, senha: string) => {
    isLoading.value = true;
    api.post(
      {
        path: "/api/user/authentication",
        data: {
          email: email,
          senha: senha,
        },
      },
      (data) => {
        isLoading.value = false;
        sessionStorage.setItem(SessionStorage.AUTH_TOKEN, data.token);
        setUser(data);
        navigate("/");
      },
      (error) => {
        isLoading.value = false;
        console.log(error);
        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
          setNewLog({
            type: "WARNING",
            message: "Email ou senha incorrectos.",
          });
        } else if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else if (error.status === HTTP_STATUS.NOT_FOUND) {
          setNewLog({
            type: "WARNING",
            message: "Não existe uma conta associada a este email.",
          });
        } else if (error.status === HTTP_STATUS.USER_BLOCKED) {
          setNewLog({
            type: "WARNING",
            message: "Conta bloqueada.",
          });
        }
      },
    );
  };

  return {
    isLoading,
    viewLog,
    submitLoginForm,
  };
};

export default SignInViewModel;
