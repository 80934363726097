import yup, { object, string } from "yup";

// id?: number;
// nome: string;
// email?: string;
// telefone?: string | null;
// telefone2?: string | null;
// numero_bi: string | null;
// data_nascimento: string;
// endereco: string | null;
// role?: UserType;
// senha?: string | null;
// token?: string;
// hash_confirmation?: string | null;
// status?: UserStatus;

// Schema for api signup user: request body
export const create: yup.Schema = object({
  // nome: string()
  //   .matches(/^(\S+\s+\S+.+)$/, "Por favor, forneça pelo menos dois nomes.")
  //   .required("Por favor, forneça um nome."),
  // email: string()
  //   .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "O email fornecido não é válido.")
  //   .required("Por favor, forneça um email.")
  //   .lowercase("O email deve contér letras minisculas somente"),
  // telefone: string()
  //   .required("O numero de telefone fornecido não é válido.")
  //   .matches(/^8[2-7]\d{7}$/, "O numero de telefone fornecido não é válido."),
  // telefone2: string()
  //   .required("O numero de telefone fornecido não é válido.")
  //   .matches(/^8[2-7]\d{7}$/, "O numero de telefone fornecido não é válido."),
  // numero_bi: string().required("Por favor, forneça o numero de bi."),
  // endereco: string().required("Por favor, forneça o seu endereço."),
  // role: mixed<UserType>()
  //   .oneOf(Object.values(UserType))
  //   .required(
  //     "Defina o tipo de usuario Administrador | Brigadista | Linha do Cliente",
  //   ),
});

export const updateMyInfo: yup.Schema = object({
  // nome: string()
  //   .matches(/^(\S+\s+\S+.+)$/, "Por favor, forneça pelo menos dois nomes.")
  //   .required("Por favor, forneça um nome."),
  // email: string()
  //   .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "O email fornecido não é válido.")
  //   .required("Por favor, forneça um email.")
  //   .lowercase("O email deve contér letras minisculas somente"),
  // telefone: string()
  //   .required("O numero de telefone fornecido não é válido.")
  //   .matches(/^8[2-7]\d{7}$/, "O numero de telefone fornecido não é válido."),
  // telefone2: string()
  //   .nullable()
  //   .matches(/^8[2-7]\d{7}$/, "O numero de telefone fornecido não é válido."),
  // data_nascimento: string().required("Insira a sua data de nascimento."),
  // numero_bi: string().required("Por favor, forneça o numero de bi."),
  // role: string().required("Por favor, forneça o tipo de acesso do usuário."),
  // endereco: string().required("Por favor, forneça o seu endereço."),
});

export const authentication: yup.Schema = object({
  email: string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "O email fornecido não é válido.")
    .required("Por favor, forneça um email.")
    .lowercase("O email deve contér letras minisculas somente"),
  senha: string()
    .required("Por favor, forneça a nova password.")
    .matches(/^\d{6,15}$/, "A senha deve ser 6 a 15 digitos."),
});

export const nullValidation: yup.Schema = object({});
