import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import { User } from "../../types/usuario";

const PerfilUpdateViewModel = () => {
  const { id } = useParams();

  const isLoading = useBetterState<boolean>(false);
  const { viewLog, setNewLog } = useViewLog();

  const navigate = useNavigate();

  const submitUpdateAccount = (userForm: User) => {
    isLoading.value = true;
    api.post(
      {
        path: "/api/user/update/user",
        data: userForm,
        params: {
          id: userForm.id,
        },
      },
      (data) => {
        isLoading.value = false;
        setNewLog({
          type: "SUCCESS",
          message: "Dados actualizados com sucesso.",
        });
      },
      (error) => {
        isLoading.value = false;
        console.log(error);
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  return {
    id,
    isLoading,
    viewLog,
    setNewLog,
    submitUpdateAccount,
  };
};

export default PerfilUpdateViewModel;
