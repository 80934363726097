import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import { User } from "../../types/usuario";
import useAppInfo from "../../zustand/app-info-zus";

const PerfilViewModel = () => {
  const { user, setUser, setLogout } = useAppInfo();

  const isLoading = useBetterState<boolean>(false);
  const { viewLog, setNewLog } = useViewLog();

  const navigate = useNavigate();

  const submitUpdateAccount = (userForm: User) => {
    isLoading.value = true;
    api.post(
      {
        path: "/api/user/update/user",
        data: userForm,
        params: {
          id: user?.id,
        },
      },
      (data) => {
        isLoading.value = false;
        setUser(data);
        setNewLog({
          type: "SUCCESS",
          message: "Dados actualizados com sucesso.",
        });
      },
      (error) => {
        isLoading.value = false;
        console.log(error);
        if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  const handleLogout = () => {
    setLogout();
    sessionStorage.removeItem("chaveDoItem");
    navigate("/authentication");
  };

  return {
    user,
    setUser,
    isLoading,
    viewLog,
    setNewLog,
    submitUpdateAccount,
    handleLogout,
  };
};

export default PerfilViewModel;
