import { Navigate } from "react-router-dom";
import { UserType } from "../../types/usuario";
import useAppInfo from "../../zustand/app-info-zus";

const AdminSecurity = ({ children }: any) => {
  const { user } = useAppInfo();

  return user !== undefined && user.role === UserType.Administrador ? (
    <div className="flex flex-1 w-full">{children}</div>
  ) : (
    <Navigate to={"/"} />
  );
};

export default AdminSecurity;
