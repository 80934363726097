import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import AvaliationForm from "../../components/client/avaliation-form";
import ClientForm from "../../components/client/client-form";
import Ilegal from "../../components/client/ilegal-form";
import Potencial from "../../components/client/potencial-form";
import HeaderForm from "../../components/header-form";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import { Client } from "../../types/client";
import { clientFormSchema } from "../../utils/schemas/client";
import ClientViewModel from "../../viewmodel/clientes/add-client";
import useAppInfo from "../../zustand/app-info-zus";
import imgPreview from "./../../assets/img/img_preview.png";

const AddClient = () => {
  const navigate = useNavigate();

  // STATE MANAGEMENT FOR CONTAINED SEARCH CLIENT
  const [clientSea, setClientSea] = useState<undefined | Client>(undefined);
  const [chave, setChave] = useState("");
  const [showPlaceholder, setShowPlaceHolder] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState({
    key: "",
    type: "",
  });
  const { viewLog: viewLog2, setNewLog } = useViewLog();
  // STATE MANAGEMENT FOR CONTAINED SEARCH CLIENT

  const {
    isLoading,
    setIsUpdate,
    viewLog,
    setNewLog: setNewLogF,
    handleSubmitForm,
    listenerChangeNC,
  } = ClientViewModel();

  const { user } = useAppInfo();

  const foto1 = useBetterState<undefined | any>(undefined);
  const foto2 = useBetterState<undefined | any>(undefined);
  const fotobalisa = useBetterState<undefined | any>(undefined);

  const defaultValues = {
    nome: null,
    email: null,
    contacto1: null,
    contacto2: null,
    contacto3: null,
    contacto4: null,
    area_operacional: user?.area_operacional,
    bairro: user?.bairro,
    avenida_rua: null,
    n_casa_rua: null,
    flat: null,
    andar: null,
    numero_de_agregado: null,
    casa_propria: null,
    tem_recebido_factura: null,
    agua_sai_regularidade: null,
    agua_sai_regularidade_desc: null,
    estado_cavalete: null,
    cavalete_tem_fugas: null,
    estado_do_contador: null,
    diametro_contador: null,
    regularidade_leitura: null,
    avaliacao_adrmm: null,
    numero_contador: null,
    leitura_actual: null,
    data_registro: null,
    situacao_entrevistado: null,
    tipo_actividade: null,
    fonte_agua: null,
    fonte_agua_desc: null,
    suspeita_ilegal: null,
    foto_contador: imgPreview,
    foto_residencia: imgPreview,
    foto_balisa: imgPreview,
    latitude: -25.9129344,
    longitude: 32.5681152,
    observacao: null,
    sem_adrmm_porque: null,
    senhorio_nome: null,
    senhorio_contacto1: null,
    senhorio_contacto2: null,
    interessado_na_adrmm: null,
    tipo_registo: null,
    id_registrador: null,
    parceiro_negocio: null,
    conta_contrato: null,
    instalacao: null,
    unidade_leitura: null,
    zona: null,
    rua: null,
    tipo_instalacao: null,
  };

  return (
    <div className="w-full bg-blue-100">
      <div className="w-full flex flex-1 flex-col bg-blue-100">
        <div className="pt-[40px] pb-[20px]">
          <HeaderForm />
        </div>
        <Formik
          validationSchema={clientFormSchema}
          initialValues={defaultValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmitForm(
              {
                ...values,
                ...{ id: clientSea?.id },
              },
              foto1.value,
              foto2.value,
              fotobalisa.value,
              () => {},
            );
          }}
        >
          {({
            values,
            setValues,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => {
            const loadExcel = (key: string, type: string) => {
              console.log(type, key);
              setLoading(true);
              api.get(
                {
                  path: "/api/cliente/form",
                  params: {
                    type: type,
                    key: key,
                  },
                },
                (data) => {
                  setLoading(false);
                  const client: any[] = data;
                  if (client.length === 0) {
                    setNewLog({
                      type: "WARNING",
                      message:
                        "Não foi possivél encontrar o cliente, tente outro filtro.",
                    });
                  } else if (client.length > 1) {
                    setNewLog({
                      type: "WARNING",
                      message:
                        "Foi encontrado mais de um cliente nesta pesquisa, tente outro tipo de filtro.",
                    });
                  } else if (client.length === 1) {
                    setIsUpdate(true);
                    setFieldValue("nome", client[0].nome);
                    setFieldValue("contacto1", client[0].contacto1);
                    setFieldValue("bairro", client[0].bairro);
                    setFieldValue("n_casa_rua", client[0].n_casa_rua);
                    setFieldValue("numero_contador", client[0].numero_contador);
                    setFieldValue(
                      "area_operacional",
                      client[0].area_operacional,
                    );
                    setNewLog({
                      type: "SUCCESS",
                      message: "Cliente encontrado com sucesso.",
                    });
                    setClientSea(client[0]);
                  }
                },
                (errors) => {
                  setLoading(false);
                  if (errors.status == HTTP_STATUS.BAD_REQUEST) {
                    setNewLog({
                      type: "WARNING",
                      message:
                        "Não foi encontrado cliente com essa especificação.",
                    });
                  } else if (errors.status === HTTP_STATUS.NETWORK_ERROR) {
                    setNewLog({
                      type: "WARNING",
                      message: "Erro de conexão, tente novamente.",
                    });
                  } else {
                    setNewLog({
                      type: "WARNING",
                      message:
                        "Erro inesperado, se persistir contacte o administrador.",
                    });
                  }
                },
              );
            };

            return (
              <div className="w-full">
                <div className="flex flex-1 flex-col items-center">
                  <div className=" flex px-[30px] flex-row w-full justify-center md:max-w-[600px] xl:max-w-[800px]">
                    <AvaliationForm
                      setFieldValue={setFieldValue}
                      values={values}
                      errors={errors}
                      renderCliente={() => {
                        return (
                          <ClientForm
                            handleChangeNc={(type, key) => {
                              setChave(key);
                              const st = {
                                type: type,
                                key: key,
                              };
                              setDataSearch(st);
                            }}
                            valorPesquisa={chave}
                            values={values}
                            errors={errors}
                            isLoading={isLoading.value}
                            viewLog={viewLog}
                            setFieldValue={setFieldValue}
                            onGetPhoto={(key, file) => {
                              if (key == "F1") {
                                foto1.value = file;
                              } else if (key == "F2") {
                                foto2.value = file;
                              } else {
                                fotobalisa.value = file;
                              }
                            }}
                            onClick={() => {
                              if (Object.values(errors).length > 0) {
                                console.log(errors);
                                setNewLogF({
                                  type: "WARNING",
                                  message:
                                    "Certifica-te de que preencheu todos campos obrigátorios.",
                                });
                                return;
                              }

                              handleSubmit();
                            }}
                            isLoadingSearch={loading}
                            handleClickSearch={() => {
                              if (chave.trim() === "") {
                                setNewLog({
                                  type: "WARNING",
                                  message: "Insira a palavra chave.",
                                });
                              } else {
                                loadExcel(dataSearch.key, dataSearch.type);
                              }
                            }}
                            searchViewLog={viewLog2}
                            showPlaceholder={showPlaceholder}
                          />
                        );
                      }}
                      renderNaoCliente={() => {
                        return (
                          <Ilegal
                            values={values}
                            errors={errors}
                            viewLog={viewLog}
                            isLoading={isLoading.value}
                            onClick={() => {
                              handleSubmit();
                            }}
                            setFieldValue={setFieldValue}
                            onGetPhoto={(key, file) => {
                              if (key == "F1") {
                                foto1.value = file;
                              } else if (key == "F2") {
                                foto2.value = file;
                              } else {
                                fotobalisa.value = file;
                              }
                            }}
                          />
                        );
                      }}
                      renderPotencial={() => {
                        return (
                          <Potencial
                            values={values}
                            errors={errors}
                            viewLog={viewLog}
                            isLoading={isLoading.value}
                            onClick={() => {
                              handleSubmit();
                            }}
                            setFieldValue={setFieldValue}
                            onGetPhoto={(key, file) => {
                              if (key == "F1") {
                                foto1.value = file;
                              } else if (key == "F2") {
                                foto2.value = file;
                              } else {
                                fotobalisa.value = file;
                              }
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddClient;
