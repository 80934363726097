import { useEffect, useState } from "react";
import api from "../../api";
import { User } from "../../types/usuario";

interface ListaProps<T> {
  data: T[];
  status: "SUCCESS" | "ERROR" | "LOADING";
}

export type { ListaProps };

const ListaBrigadistasViewModel = () => {
  const [brigadistas, setBrigadistas] = useState<ListaProps<User>>({
    data: [],
    status: "SUCCESS",
  });

  useEffect(() => {
    loadBrigadistas();
  }, []);

  const loadBrigadistas = () => {
    api.get(
      {
        path: "/api/user/all",
        params: {
          type: "BRI",
        },
      },
      (data) => {
        // const
        setBrigadistas({
          status: "SUCCESS",
          data: data,
        });
      },
      (errors) => {
        setBrigadistas({
          status: "ERROR",
          data: [],
        });
      },
    );
  };

  return {
    brigadistas,
    loadBrigadistas,
  };
};

export default ListaBrigadistasViewModel;
