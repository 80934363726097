import { Alert, Button } from "@mui/material";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import CosButton from "../../components/CosButton";
import CosTextField from "../../components/CosTextFiel";
import schemas from "../../utils/schemas";
import ForgetEmailViewModel from "../../viewmodel/authentication/forgot-email";

const ForgotEmail = () => {
  const { isLoading, viewLog, submitFindAccount } = ForgetEmailViewModel();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-[15px]">
      <Formik
        initialValues={{
          email: "",
          senha: "1234567890",
        }}
        validationSchema={schemas.authentication}
        onSubmit={(values) => {
          submitFindAccount(values.email);
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <div className="flex flex-1 flex-col gap-[15px]">
              <div className="flex flex-col">
                <p className="text-[19px] font-bold text-center tablet:text-left">
                  Insira seu email
                </p>
              </div>
              <CosTextField
                id="outlined-basic"
                size="small"
                label="Email"
                className="w-[300px]"
                variant="outlined"
                field="email"
                value={values.email}
                setFieldValue={setFieldValue}
                errorMessage={errors.email}
              />
              {viewLog.show && (
                <div className="w-[300px]">
                  <Alert severity="warning">{viewLog.message}</Alert>
                </div>
              )}
              <CosButton
                isLoading={isLoading.value}
                variant="contained"
                onClick={() => {
                  if (Object.values(errors).length > 0) {
                    return;
                  }
                  handleSubmit();
                }}
              >
                PROCURAR
              </CosButton>
              <Link to={"/authentication"}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#007C00", width: "100%" }}
                >
                  VOLTAR
                </Button>
              </Link>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForgotEmail;
