import { Button, ButtonProps, CircularProgress } from "@mui/material";

type CosButtonProps = ButtonProps & {
  isLoading?: boolean;
};

const CosButton = ({
  isLoading = false,
  children,
  onClick,
  ...props
}: CosButtonProps) => {
  return (
    <Button
      {...props}
      onClick={(event) => {
        if (!isLoading) {
          onClick?.(event);
        }
      }}
      variant="contained"
      className="h-[38px]"
    >
      {isLoading ? <CircularProgress size={18} color="inherit" /> : children}
    </Button>
  );
};

export default CosButton;
