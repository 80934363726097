import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface AppConfigState {
  bairros: any[];
  areas: any[];
  setBairros: (data: any[]) => void;
  setAreas: (data: any[]) => void;
}

const useResourcesZus = create<AppConfigState>()(
  persist(
    (set) => ({
      bairros: [],
      areas: [],
      setBairros: (bairros: any[]) => {
        set(() => ({
          bairros: bairros,
        }));
      },
      setAreas: (areas: any[]) => {
        set(() => ({
          areas: areas,
        }));
      },
    }),
    {
      name: "resource-info",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useResourcesZus;
