import "dotenv/config";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Error404 from "./examples/404";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./routes/home";
// Auth routes
import Auth from "./routes/authenticaion/auth";
import ForgotConfirmation from "./routes/authenticaion/forgot-confirmation";
import ForgotEmail from "./routes/authenticaion/forgot-email";
import ForgotNewPassword from "./routes/authenticaion/forgot-newpassword";
import SignIn from "./routes/authenticaion/sign-in";

// Clients
import BrigadistaPerfil from "./routes/brigadistas/brigadista-info";
import BrigadistasLista from "./routes/brigadistas/lista";
import ActualizarCliente from "./routes/clients/actualizar";
import AddClient from "./routes/clients/add-client";
import ClientesLista from "./routes/clients/lista";
import VerCliente from "./routes/clients/ver-cliente";
import VerPotencial from "./routes/clients/ver-potencial";
import Maps from "./routes/maps";
import AdminSecurity from "./routes/private/security-admin";
import AdminCallSecurity from "./routes/private/security-admin-call";
import BaseSecurity from "./routes/private/security-base";
import BrigadistaContainer from "./routes/private/security-base-nav";
import BrigadistasListaAdmin from "./routes/relatorio/brigadistas";
import ClientListaAdmin from "./routes/relatorio/clientes";
import MapaGeral from "./routes/relatorio/mapa-geral";
import AddUsuario from "./routes/usuarios/add";
import UsuariosLista from "./routes/usuarios/lista";
import Perfil from "./routes/usuarios/perfil";
import PerfilUpdate from "./routes/usuarios/update";

document.title = "ADRMM";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const router = createBrowserRouter([
  {
    // Rotas de autenticação
    path: "/authentication",
    element: <Auth />,
    children: [
      {
        path: "/authentication",
        element: <SignIn />,
      },
      {
        path: "forgot-email",
        element: <ForgotEmail />,
      },
      {
        path: "forgot-confirmation",
        element: <ForgotConfirmation />,
      },
      {
        path: "forgot-newpassword",
        element: <ForgotNewPassword />,
      },
    ],
  },
  {
    // Rotas gerais
    path: "/",
    element: <App />,
    errorElement: <Error404 />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "lista-brigadistas",
        element: (
          <AdminCallSecurity>
            <BrigadistasLista />
          </AdminCallSecurity>
        ),
      },
      // {
      //   path: "contact/:id",
      //   element: <ContactDetails />,
      // },
      // {
      //   path: "contact/:id",
      //   element: <ContactDetails />,
      // },
    ],
  },
  {
    path: "/geral/nav",
    element: <BrigadistaContainer />,
    children: [
      {
        path: "lista-clientes",
        element: <ClientesLista />,
      },
      {
        path: "minha-conta",
        element: <Perfil />,
      },
      {
        path: "brigadista-conta/:id",
        element: <BrigadistaPerfil />,
      },
      {
        path: "info-cliente/:id",
        element: <VerCliente />,
      },
      {
        path: "info-potencial/:id",
        element: <VerPotencial />,
      },
    ],
  },
  {
    path: "/gerais",
    element: <BaseSecurity />,
    children: [
      {
        path: "add-client",
        element: <AddClient />,
      },
      {
        path: "map",
        element: <Maps />,
      },
      {
        path: "actualizar-cliente/:id",
        element: (
          <AdminSecurity>
            <ActualizarCliente />
          </AdminSecurity>
        ),
      },
    ],
  },
  {
    path: "/admin",
    element: <App />,
    children: [
      {
        path: "add-usuario",
        element: (
          <AdminSecurity>
            <AddUsuario />
          </AdminSecurity>
        ),
      },
      {
        path: "lista-usuario",
        element: (
          <AdminSecurity>
            <UsuariosLista />
          </AdminSecurity>
        ),
      },
      {
        path: "mapa-geral",
        element: (
          <AdminSecurity>
            <MapaGeral />
          </AdminSecurity>
        ),
      },
      {
        path: "clientes",
        element: (
          <AdminSecurity>
            <ClientListaAdmin />
          </AdminSecurity>
        ),
      },
      {
        path: "brigadistas",
        element: (
          <AdminSecurity>
            <BrigadistasListaAdmin />
          </AdminSecurity>
        ),
      },
      {
        path: "update-user/:id",
        element: (
          <AdminSecurity>
            <PerfilUpdate />
          </AdminSecurity>
        ),
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
