import { Link } from "react-router-dom";
import MenuItem from "./menu-item";
import { useBetterState } from "../../hooks/use-better-state";
import menu from "./../../assets/svg/menu.svg";
import { ReactComponent as Circle } from "./../../assets/svg/circle.svg";
import { ArrowDown, ArrowRight } from "../../assets/svg";
import ArrowR from "./../../assets/svg/arrow-right.svg";
import ArrowD from "./../../assets/svg/arrow-down.svg";

interface MenuItemProps {
  path?: string;
  label: string;
  children?: React.ReactNode;
}

const SubMenu = ({ path = "", label, children, ...props }: MenuItemProps) => {
  const open = useBetterState(false);

  return (
    <div>
      <div>
        <button
          className={`flex w-full ${!open.value ? "bg-[#fff]" : "bg-[#E5E7EB]"} flex-row items-center justify-between text-[#6F6C78] hover:bg-gray-200 px-[10px] py-[5px] rounded-[2px]`}
          onClick={() => {
            open.value = !open.value;
          }}
        >
          <p className="text-[16px]">{label}</p>
          <img className="w-[10px]" src={open.value ? ArrowD : ArrowR} />
        </button>
      </div>
      {open.value && (
        <div className=" flex flex-col gap-[8px] ml-[10px] mt-[7px]">
          {children}
        </div>
      )}
    </div>
  );
};

export default SubMenu;
