import { useEffect } from "react";
import api from "../../api";
import useUsuariosZus from "../../zustand/usuarios-zus";

const ListaUsuarioViewModel = () => {
  const { usuarios, setUsuarios } = useUsuariosZus();

  useEffect(() => {
    loadUsuarios();
  }, []);

  const loadUsuarios = () => {
    api.get(
      {
        path: "/api/user/all",
        params: {
          type: "ALL",
        },
      },
      (data) => {
        // const
        setUsuarios(data);
      },
      (errors) => {
        console.log(errors);
      },
    );
  };

  return {
    usuarios,
    setUsuarios,
    loadUsuarios,
  };
};

export default ListaUsuarioViewModel;
