import axios from "axios";
import { httpResponse } from "./api-response-status";
import apiClient, { googleDrivePhoto } from "./setup";

interface RequestProps {
  path: string;
  data?: object | undefined;
  headers?: object | undefined;
  params?: object | undefined;
}

const getRequest = async (
  props: RequestProps,
  response?: (data: any) => void,
  errorResponse?: (error: any) => void,
) => {
  try {
    const result = await apiClient.get(props.path, {
      headers: props.headers,
      params: props.params,
    });

    if (response === undefined) {
      return httpResponse("SUCCESS", result.data);
    } else {
      response(result.data);
    }
  } catch (error) {
    if (errorResponse === undefined) {
      if (axios.isAxiosError(error)) {
        return httpResponse("ERROR", error.response?.data);
      }
    } else {
      if (axios.isAxiosError(error)) {
        errorResponse(error.response?.data);
      }
    }
  }
};

const postRequest = async (
  props: RequestProps,
  response?: (data: any) => void,
  errorResponse?: (error: any) => void,
) => {
  try {
    const result = await apiClient.post(props.path, props.data, {
      headers: props.headers,
      params: props.params,
    });

    if (response === undefined) {
      return httpResponse("SUCCESS", result.data);
    } else {
      response(result.data);
    }
  } catch (error) {
    if (errorResponse === undefined) {
      if (axios.isAxiosError(error)) {
        return httpResponse("ERROR", error.response?.data);
      }
    } else {
      if (axios.isAxiosError(error)) {
        errorResponse(error.response?.data);
      }
    }
  }
};

const deleteRequest = async (
  props: RequestProps,
  response?: (data: any) => void,
  errorResponse?: (error: any) => void,
) => {
  try {
    const result = await apiClient.delete(props.path, {
      headers: props.headers,
      params: props.params,
    });

    if (response === undefined) {
      return httpResponse("SUCCESS", result.data);
    } else {
      response(result.data);
    }
  } catch (error) {
    if (errorResponse === undefined) {
      if (axios.isAxiosError(error)) {
        return httpResponse("ERROR", error.response?.data);
      }
    } else {
      if (axios.isAxiosError(error)) {
        errorResponse(error.response?.data);
      }
    }
  }
};

const api = {
  googleDrivePhoto: googleDrivePhoto,
  get: getRequest,
  post: postRequest,
  delete: deleteRequest,
};

export default api;
