import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import api from "../../api";
import CosButton from "../../components/CosButton";
import CosTextField from "../../components/CosTextFiel";
import MyAlert from "../../components/my-alter";
import { User, UserStatus, UserType } from "../../types/usuario";
import schemas from "../../utils/schemas";
import PerfilUpdateViewModel from "../../viewmodel/usuarios/update";
import useResourcesZus from "../../zustand/resources-zus";

const defaultValues = {
  nome: "",
  email: "",
  telefone: "",
  telefone2: "",
  numero_bi: "",
  area_operacional: "",
  bairro: "",
  data_nascimento: "",
  endereco: "",
  role: "",
  status: "",
};

const PerfilUpdate = () => {
  const { bairros, areas } = useResourcesZus();

  const { id, isLoading, setNewLog, viewLog, submitUpdateAccount } =
    PerfilUpdateViewModel();

  const [userState, setUserState] = useState<User | undefined>();

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      {}
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="py-[20px] pt-[40px]">
          <div className="w-full flex flex-col justify-center items-center">
            <p className=" text-[23px] font-bold text-black-800 text-center tablet:text-left">
              {"Actualizar Conta Usuário"}
            </p>
            <p className=" text-[14px] text-center tablet:text-left">
              {
                "Ao actualizar os dados deve clicar em actualizar para salvá-los."
              }
            </p>
          </div>
        </div>
        <Formik
          initialValues={defaultValues}
          validationSchema={schemas.usuario.updateMyInfo}
          onSubmit={(values) => {
            submitUpdateAccount({
              ...(userState as User),
              ...(values as User),
            });
          }}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => {
            return (
              <div className="flex flex-1 flex-col items-center gap-[15px] ">
                <HelperComponent
                  id={Number(id)}
                  onChange={(user) => {
                    setUserState(user as User);
                    const date = new Date(user.data_nascimento);
                    setFieldValue("nome", user.nome);
                    setFieldValue("email", user.email);
                    setFieldValue("telefone", user.telefone);
                    setFieldValue("telefone2", user.telefone2);
                    setFieldValue("area_operacional", user.area_operacional);
                    setFieldValue("bairro", user.bairro);
                    setFieldValue("role", user.role);
                    setFieldValue(
                      "data_nascimento",
                      `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                    );
                    setFieldValue("numero_bi", user.numero_bi);
                    setFieldValue("status", user.status);
                    setFieldValue("nome", user.nome);
                    setFieldValue("endereco", user.endereco);
                  }}
                />
                <div className="w-full flex flex-1 flex-col items-center gap-[15px] px-[30px] pb-[40px] tablet:pl-[40px] tablet:max-w-[650px]">
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Nome completo *"
                    autoComplete="off"
                    size="small"
                    placeholder="Nome Completo *"
                    errorMessage={errors.nome}
                    value={values.nome}
                    field="nome"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    disabled
                    fullWidth
                    variant="outlined"
                    label="Email *"
                    size="small"
                    placeholder="Email *"
                    errorMessage={errors.email}
                    value={values.email}
                    field="email"
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Telefone *"
                    size="small"
                    autoComplete="off"
                    placeholder="Telefone *"
                    errorMessage={errors.telefone}
                    value={values.telefone}
                    field="telefone"
                    setFieldValue={setFieldValue}
                  />
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Telefone Alternativo"
                    size="small"
                    autoComplete="off"
                    placeholder="Telefone Alternativo"
                    errorMessage={errors.telefone2}
                    value={values.telefone2}
                    field="telefone2"
                    setFieldValue={setFieldValue}
                  />
                  {userState !== undefined &&
                    userState.role === "Brigadista" && (
                      <>
                        <FormControl size="small" fullWidth variant="outlined">
                          <InputLabel id="demo-simple-select-label">
                            Área Operacional *
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={values.area_operacional}
                            label="Área Operacional *"
                            onChange={(event) => {
                              setFieldValue?.(
                                "area_operacional",
                                event.target.value,
                              );
                            }}
                          >
                            {areas.map((item) => {
                              return (
                                <MenuItem value={item.area_operacional}>
                                  {item.area_operacional}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <FormControl size="small" fullWidth variant="outlined">
                          <InputLabel id="bairro_drop">Bairro *</InputLabel>
                          <Select
                            labelId="bairro_drop"
                            value={values.bairro}
                            label="Bairro *"
                            onChange={(event) => {
                              setFieldValue?.("bairro", event.target.value);
                            }}
                          >
                            {bairros.map((item: any) => {
                              return (
                                <MenuItem value={item.bairro}>
                                  {item.bairro}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="contracto_drop">
                      Tipo de acesso *
                    </InputLabel>
                    <Select
                      labelId="contracto_drop"
                      value={values.role}
                      label="Tipo de acesso *"
                      onChange={(event) => {
                        setFieldValue("role", event.target.value);
                      }}
                    >
                      <MenuItem value={UserType.Administrador}>
                        {UserType.Administrador}
                      </MenuItem>
                      <MenuItem selected={true} value={UserType.Brigadista}>
                        {UserType.Brigadista}
                      </MenuItem>
                      <MenuItem value={UserType["Linha do Cliente"]}>
                        {UserType["Linha do Cliente"]}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="contracto_drop">
                      Estado da Conta *
                    </InputLabel>
                    <Select
                      labelId="contracto_drop"
                      value={values.status}
                      label="Estado da Conta *"
                      onChange={(event) => {
                        setFieldValue("status", event.target.value);
                      }}
                    >
                      <MenuItem value={UserStatus.Activo}>
                        {UserStatus.Activo}
                      </MenuItem>
                      <MenuItem selected={true} value={UserStatus.Bloqueada}>
                        {UserStatus.Bloqueada}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Numero do B.I *"
                    size="small"
                    placeholder="Numero do B.I *"
                    errorMessage={errors.numero_bi}
                    value={values.numero_bi}
                    autoComplete="off"
                    field="numero_bi"
                    setFieldValue={setFieldValue}
                  />
                  <div className="flex flex-1 w-full flex-col">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DateField", "DateField"]}>
                        <DateField
                          fullWidth
                          size="small"
                          label="Data de nascimento *"
                          value={dayjs(values.data_nascimento)}
                          format="DD-MM-YYYY"
                          onChange={(event) => {
                            setFieldValue(
                              "data_nascimento",
                              event?.format("YYYY-MM-DD"),
                            );
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <CosTextField
                    fullWidth
                    variant="outlined"
                    label="Endereço *"
                    size="small"
                    placeholder="Endereço *"
                    autoComplete="off"
                    errorMessage={errors.endereco}
                    value={values.endereco}
                    field="endereco"
                    setFieldValue={setFieldValue}
                  />
                  {viewLog.show && (
                    <div className="w-full flex flex-1 justify-center items-center">
                      <MyAlert type={viewLog.type} message={viewLog.message} />
                    </div>
                  )}

                  <CosButton
                    isLoading={isLoading.value}
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      if (Object.values(errors).length > 0) {
                        setNewLog({
                          type: "WARNING",
                          message:
                            "Cerifique que todos os campos obrigátrios estão preenchidos e correctamente.",
                        });
                        return;
                      }
                      handleSubmit();
                    }}
                  >
                    ACTUALIZAR
                  </CosButton>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PerfilUpdate;

interface HelperComponentProps {
  id: number;
  onChange: (user: User) => void;
}

const HelperComponent = ({ id, onChange }: HelperComponentProps) => {
  useEffect(() => {
    loadMyProfile(id);
  }, []);

  const loadMyProfile = (id: number) => {
    api.get(
      {
        path: "/api/user/find",
        params: {
          id: id,
        },
      },
      (data) => {
        const dataUser = data as User;
        onChange(dataUser);
      },
      (errors) => {},
    );
  };

  return <div />;
};
