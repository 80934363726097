import { Alert, Button } from "@mui/material";
import { Formik } from "formik";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CosButton from "../../components/CosButton";
import CosTextField from "../../components/CosTextFiel";
import schemas from "../../utils/schemas";
import ForgotConfirmationViewModel from "../../viewmodel/authentication/forgot-confirmation";
import useAppInfo from "../../zustand/app-info-zus";

const ForgotConfirmation = () => {
  const { userC } = useAppInfo();
  const navigate = useNavigate();

  const { isLoading, viewLog, submitCode } = ForgotConfirmationViewModel();

  return userC !== undefined ? (
    <div className="flex flex-col gap-[15px]">
      <Formik
        initialValues={{
          ...schemas.geral.values,
          codico: "",
        }}
        validationSchema={schemas.geral.schema}
        onSubmit={(values) => {
          submitCode(values.codico);
        }}
      >
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <div className="flex flex-1 flex-col gap-[15px]">
              <div className="flex flex-col">
                <p className="text-[19px] font-bold text-center tablet:text-left">
                  Insira o códico de confirmação
                </p>
                <span className=" text-center tablet:text-left">
                  Verifique sua caixa de entrada de emails
                </span>
                <span className=" font-bold text-[14px] text-center tablet:text-left">
                  Email: {userC.email}
                </span>
              </div>
              <CosTextField
                id="outlined-basic"
                size="small"
                label="Códico de confirmação"
                className="w-[300px]"
                variant="outlined"
                value={values.codico}
                field="codico"
                setFieldValue={setFieldValue}
                errorMessage={errors.codico}
              />
              {viewLog.show && (
                <div className="w-[300px]">
                  <Alert severity="warning">{viewLog.message}</Alert>
                </div>
              )}
              <CosButton
                variant="contained"
                isLoading={isLoading.value}
                onClick={() => {
                  console.log(errors);
                  if (Object.values(errors).length > 0) {
                    return;
                  }
                  handleSubmit();
                }}
              >
                CONFIRMAR
              </CosButton>
              <Link to={"/authentication"}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#007C00", width: "100%" }}
                >
                  CANCELAR
                </Button>
              </Link>
            </div>
          );
        }}
      </Formik>
    </div>
  ) : (
    <Navigate to={"/authentication"} />
  );
};

export default ForgotConfirmation;
