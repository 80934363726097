import { useEffect, useState } from "react";
import api from "../api";

interface EstadoContador {
  Bom: number;
  Sem: number;
  Avariado: number;
  NaoV: number;
  null: number;
  total: number;
}

interface Situacao {
  Correcto: number;
  Incompleto: number;
  Irregular: number;
  null: number;
  total: number;
}

const HomeViewModel = () => {
  const [estado, setEstado] = useState<EstadoContador>({
    Bom: 0,
    Sem: 0,
    Avariado: 0,
    NaoV: 0,
    null: 0,
    total: 0,
  });

  const [situacao, setSituacao] = useState<Situacao>({
    Correcto: 0,
    Incompleto: 0,
    Irregular: 0,
    null: 0,
    total: 0,
  });

  useEffect(() => {
    loadEstado();
    loadSituacao();
  }, []);

  const loadEstado = () => {
    api.get(
      {
        path: "/api/resources/estado",
      },
      (data) => {
        setEstado(data);
      },
    );
  };

  const loadSituacao = () => {
    api.get(
      {
        path: "/api/resources/situacao",
      },
      (data) => {
        setSituacao(data);
      },
    );
  };

  return {
    estado,
    situacao,
  };
};

export default HomeViewModel;
