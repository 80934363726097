interface LabelInfoProps {
  title: string;
  value: string;
}

const LabelInfo = ({ title, value }: LabelInfoProps) => {
  return (
    <div className="flex flex-col w-full border-b-[1px] border-black-600 px-[5px]">
      <span className="text-[9px]">{title}</span>
      <span className=" text-black-900 ">{value}</span>
    </div>
  );
};

export default LabelInfo;
