import { Navigate, Outlet } from "react-router-dom";
import useAppInfo from "../../zustand/app-info-zus";

const BaseSecurity = () => {
  const { user } = useAppInfo();

  return user !== undefined ? (
    <div className="flex flex-1 w-full">
      <Outlet />
    </div>
  ) : (
    <Navigate to={"/"} />
  );
};

export default BaseSecurity;
