import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { User } from "../types/usuario";

interface AppConfigState {
  isLogged: boolean;
  user?: User | undefined;
  userC?: User | undefined;
  provincies: any[]; // Assuming you have this field in your state
  setIsLogged: (isLogged: boolean) => void;
  setUser: (user: any) => void;
  setUserC: (user: any) => void;
  setLogout: () => void;
}

const useAppInfo = create<AppConfigState>()(
  persist(
    (set) => ({
      isLogged: false,
      user: undefined,
      provincies: [],
      setIsLogged: (isLogged: boolean) => {
        set(() => ({
          isLogged,
        }));
      },
      setUser: (user: any) => {
        set(() => ({
          isLogged: true,
          user,
        }));
      },
      setUserC: (user: any) => {
        set(() => ({
          isLogged: false,
          userC: user,
        }));
      },
      setLogout: () => {
        set(() => ({
          isLogged: false,
          user: undefined,
          userC: undefined,
        }));
      },
    }),
    {
      name: "app-info",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useAppInfo;
