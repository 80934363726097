import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import ListaBrigadistasViewModel from "../../viewmodel/brigadistas/lista";
const BrigadistasLista = () => {
  const { brigadistas } = ListaBrigadistasViewModel();

  const handleEdit = (id: number) => {
    console.log("edit: ", id);
  };

  const columns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome completo",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      flex: 2,
      align: "center",
    },
    {
      field: "telefone",
      headerName: "Telefone",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "status",
      headerName: "Estado",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "detalhes",
      headerName: "Mais Info.",
      minWidth: 70,
      headerAlign: "center",
      renderCell: (params) => (
        <div className="flex flex-row gap-[15px] h-[47px] items-center justify-center">
          <Link to={`/geral/nav/brigadista-conta/${params.row.id}`}>
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: "#685DD8" }}
              onClick={() => {
                handleEdit(params.row.id);
              }}
            >
              Detalhes
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  const paginationModel = { page: 0, pageSize: 7 };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex flex-1 px-[40px] py-[20px]">
          <span className=" text-[20px] font-bold">Lista de Brigadistas</span>
        </div>
        <div className="w-full px-[20px] overflow-auto mb-[30px] tablet:px-[30px]">
          <Paper className="flex-1" sx={{ width: "100%" }}>
            <DataGrid
              rowSelection={false}
              rows={brigadistas.data as any[]}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[5, 15]}
              sx={{ border: 0 }}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default BrigadistasLista;
