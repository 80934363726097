import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import api from "../../api";
import CosButton from "../../components/CosButton";
import MyModalModal, { ModalStateProps } from "../../components/modal";
import { useBetterState } from "../../hooks/use-better-state";
import ListaUsuarioViewModel from "../../viewmodel/usuarios/lista";

const UsuariosLista = () => {
  const { usuarios, setUsuarios } = ListaUsuarioViewModel();

  const deleteData = useBetterState<any>({
    isLoading: false,
    data: 0,
  });
  const modalState = useBetterState<ModalStateProps>({
    show: false,
  });

  const columns: GridColDef[] = [
    {
      field: "nome",
      headerName: "Nome completo",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "center",
      flex: 2,
      align: "center",
    },
    {
      field: "role",
      headerName: "Tipo de acesso",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "status",
      headerName: "Estado",
      headerAlign: "center",
      flex: 1,
      align: "center",
    },
    {
      field: "detalhes",
      headerName: "Acção 1",
      minWidth: 70,
      headerAlign: "center",
      renderCell: (params) => (
        <Link
          to={`/admin/update-user/${params.row.id}`}
          className="flex flex-row gap-[15px] h-[47px] items-center justify-center"
        >
          <CosButton
            size="small"
            variant="contained"
            style={{ backgroundColor: "#685DD8" }}
          >
            Detalhes
          </CosButton>
        </Link>
      ),
    },
    {
      field: "remover",
      headerName: "Acção 2",
      minWidth: 70,
      headerAlign: "center",
      renderCell: (params) => (
        <div className="flex flex-row h-[47px] items-center justify-center">
          <CosButton
            isLoading={
              deleteData.value.data == params.row.id &&
              deleteData.value.isLoading
            }
            size="small"
            variant="contained"
            style={{ backgroundColor: "#ED1C39" }}
            onClick={() => {
              modalState.value = {
                data: params.row.id,
                show: true,
                message: `Tem certeza que pretende remover o usuário ${
                  usuarios.find((item) => {
                    return item.id === params.row.id;
                  })?.nome
                }.`,
              } as ModalStateProps;
            }}
          >
            Remover
          </CosButton>
        </div>
      ),
    },
  ];

  const paginationModel = { page: 0, pageSize: 7 };

  const handleDeletingData = (id: number) => {
    deleteData.value = {
      isLoading: true,
      data: id,
    };
    api.delete(
      {
        path: "/api/user/user",
        params: {
          id: Number(id),
        },
      },
      (data) => {
        deleteData.value = {
          isLoading: false,
          data: id,
        };
        setUsuarios(
          usuarios.filter((item) => {
            return item.id !== id;
          }),
        );
      },
      (errors) => {
        deleteData.value = {
          isLoading: false,
          data: id,
        };
      },
    );
  };

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <MyModalModal
        state={modalState?.value}
        handleDismiss={() => {
          modalState.value = {
            show: false,
          };
        }}
        handleNo={() => {
          modalState.value = {
            show: false,
          };
        }}
        handleYes={(data: any) => {
          modalState.value = {
            show: false,
          };
          handleDeletingData(data);
        }}
      />
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex flex-1 px-[40px] py-[20px]">
          <span className=" text-[20px] font-bold">Usuários Cadastrados</span>
        </div>
        <div className="w-full px-[20px] overflow-auto mb-[30px] tablet:px-[30px]">
          <Paper className="flex-1" sx={{ width: "100%" }}>
            <DataGrid
              rowSelection={false}
              rows={usuarios}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[5, 15]}
              sx={{ border: 0 }}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default UsuariosLista;
