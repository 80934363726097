import { create } from "zustand";
import { User } from "../types/usuario";

interface AppConfigState {
  usuarios: User[];
  setUsuarios: (users: User[]) => void;
}

const useUsuariosZus = create<AppConfigState>((set) => ({
  usuarios: [],
  setUsuarios: (users: User[]) => {
    return set((state) => {
      return {
        usuarios: users,
      };
    });
  },
}));

export default useUsuariosZus;
