import { Button } from "@mui/material";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";
import { ClientFormProps } from "../client/client-form";

interface MyLocationProps extends ClientFormProps {
  latitude: number;
  longitude: number;
  getCoordenates?: (
    coordenadas: Pick<MyLocationProps, "latitude" | "longitude">,
  ) => void;
  onError?: () => void;
}

const MyLocaction = ({
  getCoordenates,
  onError,
  setFieldValue,
  values,
  errors,
}: Omit<MyLocationProps, "latitude" | "longitude">) => {
  const [location, setLocation] = useState<MyLocationProps>({
    latitude: -25.802439,
    longitude: 32.583435,
  });

  const loadLocation = () => {
    const handleSuccess = (position: any) => {
      const { latitude, longitude } = position.coords;
      setLocation({
        latitude: latitude,
        longitude: longitude,
      });
    };

    const handleError = (error: any) => {};

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
      });
    } else {
      if (onError !== undefined) {
        onError?.();
      }
    }
  };

  useEffect(() => {
    setFieldValue?.("latitude", String(location.latitude));
    setFieldValue?.("longitude", String(location.longitude));
  }, [location]);

  useEffect(() => {
    loadLocation();
  }, []);

  return (
    <div className="flex flex-1 flex-col w-full h-[400px]">
      <APIProvider
        apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
        onLoad={() => console.log("Maps API has loaded.")}
      >
        <Map
          defaultZoom={14}
          defaultCenter={{ lat: location.latitude, lng: location.longitude }}
          center={{ lat: location.latitude, lng: location.longitude }}
        >
          <Marker
            position={{ lat: location.latitude, lng: location.longitude }}
          />
        </Map>
      </APIProvider>
      <div>
        <div className="w-full flex flex-row border-[2px] mt-[15px] border-black-300 px-[6px] items-center rounded-[8px] py-[5px]">
          <div className="flex flex-1 justify-evenly mr-[20px]">
            <span className=" font-bold text-[15px]">
              Lat :{" "}
              <span className=" font-normal">
                {location.latitude === 0 ? "XXXX" : location.latitude}
              </span>{" "}
            </span>
            |
            <span className=" font-bold">
              Lng :{" "}
              <span className=" font-normal">
                {location.longitude === 0 ? "XXXX" : location.longitude}
              </span>{" "}
            </span>
          </div>
          <Button
            onClick={() => {
              loadLocation();
            }}
            variant="contained"
          >
            ACTUALIZAR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyLocaction;
