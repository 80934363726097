import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { useEffect, useState } from "react";
import { ConstantsClientForm } from "../../constants/cliente-form";
import { Constants } from "../../constants/home-constants";
import { ViewLogProps } from "../../hooks/use-view-log";
import { AreaOperacional } from "../../types/constants";
import useResourcesZus from "../../zustand/resources-zus";
import CosButton from "../CosButton";
import CosTextField from "../CosTextFiel";
import MyLocaction from "../maps/my-locaction";
import MyAlert from "../my-alter";

interface ClientFormProps {
  setFieldValue?: (field: string, value: string) => void;
  values?: any;
  errors?: any;
  onClick?: () => void;
  onGetPhoto?: (foto: "F1" | "F2" | "FB", file: any) => void;
  isLoading?: boolean;
  viewLog?: ViewLogProps;
  handleChangeNc?: (type: string, key: string) => void;
  handleClickSearch?: () => void;
  isLoadingSearch?: boolean;
  searchViewLog?: ViewLogProps;
  showPlaceholder?: boolean;
  valorPesquisa?: string;
}

export type { ClientFormProps };

const ClientForm = ({
  setFieldValue,
  values,
  errors,
  onClick,
  onGetPhoto,
  isLoading,
  viewLog,
  handleChangeNc,
  handleClickSearch,
  isLoadingSearch,
  searchViewLog,
  showPlaceholder = true,
  valorPesquisa,
}: ClientFormProps) => {
  const pontosDePesquisa = [
    "Conta Contrato",
    "Número de Instalação",
    "Número do Contador",
    "Parceiro de Negócios",
  ];
  const [pesquisa, setPesquisa] = useState(pontosDePesquisa[0]);
  const { areas, bairros } = useResourcesZus();
  const [selectedArea, setSelectedArea] = useState<AreaOperacional>({
    name: "",
    bairros: [],
  });

  const handleChangeArea = (event: any) => {
    setSelectedArea(
      Constants.areasOperacionais.find((item) => {
        return event.target.value == item.name;
      }) as AreaOperacional,
    );
  };

  useEffect(() => {
    if (viewLog?.show && viewLog.type === "SUCCESS") {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  }, [viewLog]);

  const handleFoto1 = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      onGetPhoto?.("F1", file);
      const imageUrl = URL.createObjectURL(file);
      file.path = imageUrl;
      setFieldValue?.("foto_contador", imageUrl);
    }
  };

  const handleFoto2 = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      onGetPhoto?.("F2", file);
      const imageUrl = URL.createObjectURL(file);
      file.path = imageUrl;
      setFieldValue?.("foto_residencia", imageUrl);
    }
  };

  const handleFotoBalisa = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      onGetPhoto?.("FB", file);
      const imageUrl = URL.createObjectURL(file);
      file.path = imageUrl;
      setFieldValue?.("foto_balisa", imageUrl);
    }
  };

  const [value, setValue] = useState("1");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div>
      <div className="flex flex-1 items-center flex-col gap-[15px]">
        <div>
          <p className=" text-[18px] font-bold">Informações do Cliente ADRMM</p>
        </div>
        <div className="w-full flex flex-col gap-[15px]">
          <div className=" w-full flex flex-1 flex-col gap-[15px] tablet:flex-row tablet: justify-center">
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="bairro_drop">Tipo de pesquisa</InputLabel>
              <Select
                labelId="bairro_drop"
                value={pesquisa}
                label="Tipo de pesquisa"
                onChange={(event) => {
                  setPesquisa(event.target.value);

                  if (event.target.value === "Conta Contrato") {
                    handleChangeNc?.("CC", "");
                  } else if (event.target.value === "Número de Instalação") {
                    handleChangeNc?.("NI", "");
                  } else if (event.target.value === "Número do Contador") {
                    handleChangeNc?.("NC", "");
                  } else {
                    handleChangeNc?.("PN", "");
                  }
                }}
              >
                {pontosDePesquisa.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <div className="w-full">
              <TextField
                size="small"
                fullWidth
                placeholder={pesquisa}
                value={valorPesquisa}
                label={pesquisa}
                onChange={(event) => {
                  if (pesquisa === "Conta Contrato") {
                    handleChangeNc?.("CC", event.target.value);
                  } else if (pesquisa === "Número de Instalação") {
                    handleChangeNc?.("NI", event.target.value);
                  } else if (pesquisa === "Número do Contador") {
                    handleChangeNc?.("NC", event.target.value);
                  } else {
                    handleChangeNc?.("PN", event.target.value);
                  }
                }}
              />
            </div>
            <CosButton
              style={{ minWidth: 135 }}
              isLoading={isLoadingSearch}
              size="small"
              variant="contained"
              onClick={handleClickSearch}
            >
              PROCURAR
            </CosButton>
          </div>
          <div className="w-full py-[3px]">
            {searchViewLog?.show && (
              <MyAlert
                type={searchViewLog.type}
                message={searchViewLog.message}
              />
            )}
          </div>
        </div>
        <CosTextField
          fullWidth
          variant="outlined"
          label="Nome completo *"
          size="small"
          placeholder={showPlaceholder ? "Nome Completo *" : ""}
          value={values.nome}
          errorMessage={errors.nome}
          field="nome"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Email"
          label="Email"
          value={values.email}
          errorMessage={errors.email}
          field="email"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder={showPlaceholder ? "Contacto Telefone 1 *" : ""}
          label="Contacto Telefone 1 *"
          value={values.contacto1}
          errorMessage={errors.contacto1}
          field="contacto1"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Contacto Telefone 2"
          label="Contacto Telefone 2"
          value={values.contacto2}
          errorMessage={errors.contacto2}
          field="contacto2"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder={"Contacto Telefone 3"}
          label="Contacto Telefone 3"
          value={values.contacto3}
          errorMessage={errors.contacto3}
          field="contacto3"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Contacto Telefone"
          label="Contacto Telefone"
          value={values.contacto4}
          errorMessage={errors.contacto4}
          field="contacto4"
          setFieldValue={setFieldValue}
        />
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="cat_drop">Tipo de Actividade *</InputLabel>
          <Select
            labelId="cat_drop"
            value={values.tipo_actividade}
            label="Tipo de actividade *"
            onChange={(event) => {
              setFieldValue?.("tipo_actividade", event.target.value);
            }}
          >
            {ConstantsClientForm.categoriaCliente.map((item) => {
              return <MenuItem value={item}>{item}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <div className=" flex flex-row justify-start">
          <FormControl className="flex flex-row justify-start">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="1" control={<Radio />} label="Opcções" />
              <FormControlLabel value="2" control={<Radio />} label="Manual" />
            </RadioGroup>
          </FormControl>
        </div>
        {value === "1" ? (
          <>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Área Operacional *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.area_operacional}
                label="Área Operacional *"
                onChange={(event) => {
                  setFieldValue?.("area_operacional", event.target.value);
                  handleChangeArea(event);
                }}
              >
                {areas.map((item) => {
                  return (
                    <MenuItem value={item.area_operacional}>
                      {item.area_operacional}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="bairro_drop">Bairro *</InputLabel>
              <Select
                labelId="bairro_drop"
                value={values.bairro}
                label="Bairro *"
                onChange={(event) => {
                  setFieldValue?.("bairro", event.target.value);
                }}
              >
                {bairros.map((item) => {
                  return <MenuItem value={item.bairro}>{item.bairro}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <CosTextField
              fullWidth
              size="small"
              placeholder="Area Operacional *"
              label="Area Operacional *"
              value={values.area_operacional}
              errorMessage={errors.area_operacional}
              field="area_operacional"
              setFieldValue={setFieldValue}
            />
            <CosTextField
              fullWidth
              size="small"
              placeholder="Bairro"
              label="Bairro"
              value={values.bairro}
              errorMessage={errors.bairro}
              field="bairro"
              setFieldValue={setFieldValue}
            />
          </>
        )}
        <CosTextField
          fullWidth
          size="small"
          placeholder="Avenida/Rua *"
          label="Avenida/Rua *"
          value={values.avenida_rua}
          errorMessage={errors.avenida_rua}
          field="avenida_rua"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder={showPlaceholder ? "N. da casa/Porta" : ""}
          label="N. da casa/Porta"
          value={values.n_casa_rua}
          errorMessage={errors.n_casa_rua}
          field="n_casa_rua"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Flat"
          label="Flat"
          value={values.flat}
          errorMessage={errors.flat}
          field="flat"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Andar"
          label="Andar"
          value={values.andar}
          errorMessage={errors.andar}
          field="andar"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Numero de agregado familiar"
          label="Numero de agregado familiar"
          value={values.numero_de_agregado}
          errorMessage={errors.numero_de_agregado}
          field="numero_de_agregado"
          setFieldValue={setFieldValue}
        />
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="propria_drop">É casa própria?</InputLabel>
          <Select
            labelId="propria_dropf"
            value={values.casa_propria}
            label="É casa própria?"
            onChange={(event) => {
              setFieldValue?.("casa_propria", event.target.value);
            }}
          >
            <MenuItem value={"Sim"}>Sim</MenuItem>
            <MenuItem value={"Não"}>Não</MenuItem>
          </Select>
        </FormControl>
        <div className="mt-[10px]" />
        <div>
          <p className=" text-[18px] font-bold">Dados do Serviço</p>
        </div>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">Tem recebido factura de água?</InputLabel>
          <Select
            labelId="catw_drop"
            value={values.tem_recebido_factura}
            label="Tem recebido factura de água?"
            onChange={(event) => {
              setFieldValue?.("tem_recebido_factura", event.target.value);
            }}
          >
            <MenuItem value={"Sim"}>{"Sim"}</MenuItem>
            <MenuItem value={"Não"}>{"Não"}</MenuItem>
            <MenuItem value={"Às vezes"}>{"As vezes"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">A água sai com regularidade?</InputLabel>
          <Select
            labelId="catw_drop"
            value={values.agua_sai_regularidade}
            label="A água sai com regularidade?"
            onChange={(event) => {
              setFieldValue?.("agua_sai_regularidade", event.target.value);
            }}
          >
            <MenuItem value={"Sim"}>{"Sim"}</MenuItem>
            <MenuItem value={"Não"}>{"Não"}</MenuItem>
            <MenuItem value={"Às vezes"}>{"As vezes"}</MenuItem>
          </Select>
        </FormControl>
        {values.agua_sai_regularidade != "" &&
          (values.agua_sai_regularidade === "Sim" ? (
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="catw_drop">
                Por quantas horas tem saido por dia?
              </InputLabel>
              <Select
                labelId="catw_drop"
                value={values.agua_sai_regularidade_desc}
                label="Por quantas horas tem saido por dia?"
                onChange={(event) => {
                  setFieldValue?.(
                    "agua_sai_regularidade_desc",
                    event.target.value,
                  );
                }}
              >
                <MenuItem value={"1"}>{"1"}</MenuItem>
                <MenuItem value={"2"}>{"2"}</MenuItem>
                <MenuItem value={"3"}>{"3"}</MenuItem>
                <MenuItem value={"4"}>{"4"}</MenuItem>
                <MenuItem value={"5"}>{"5"}</MenuItem>
                <MenuItem value={"6"}>{"6"}</MenuItem>
                <MenuItem value={"7"}>{"7"}</MenuItem>
                <MenuItem value={"8"}>{"8"}</MenuItem>
                <MenuItem value={"9"}>{"9"}</MenuItem>
                <MenuItem value={"10"}>{"10"}</MenuItem>
                <MenuItem value={"11"}>{"11"}</MenuItem>
                <MenuItem value={"12"}>{"12"}</MenuItem>
                <MenuItem value={"13"}>{"13"}</MenuItem>
                <MenuItem value={"14"}>{"14"}</MenuItem>
                <MenuItem value={"15"}>{"15"}</MenuItem>
                <MenuItem value={"16"}>{"16"}</MenuItem>
                <MenuItem value={"17"}>{"17"}</MenuItem>
                <MenuItem value={"18"}>{"18"}</MenuItem>
                <MenuItem value={"19"}>{"19"}</MenuItem>
                <MenuItem value={"20"}>{"20"}</MenuItem>
                <MenuItem value={"21"}>{"21"}</MenuItem>
                <MenuItem value={"22"}>{"22"}</MenuItem>
                <MenuItem value={"23"}>{"23"}</MenuItem>
                <MenuItem value={"24"}>{"24"}</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <FormControl size="small" fullWidth variant="outlined">
              <InputLabel id="catw_drop">
                Há quanto tempo não sai água na sua casa?
              </InputLabel>
              <Select
                labelId="catw_drop"
                value={values.agua_sai_regularidade_desc}
                label="A água sai com regularidade?"
                onChange={(event) => {
                  setFieldValue?.(
                    "agua_sai_regularidade_desc",
                    event.target.value,
                  );
                }}
              >
                <MenuItem value={"Menos de uma semana"}>
                  {"Menos de uma semana"}
                </MenuItem>
                <MenuItem value={"Duas semanas"}>{"Duas semanas"}</MenuItem>
                <MenuItem value={"Um Mês"}>{"Um Mês"}</MenuItem>
                <MenuItem value={"Mais de um Mês"}>{"Mais de um Mês"}</MenuItem>
              </Select>
            </FormControl>
          ))}
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">
            Em que estado se encontra o seu cavalete?
          </InputLabel>
          <Select
            labelId="catw_drop"
            value={values.estado_cavalete}
            label="Em que estado se encontra o seu cavalete?"
            onChange={(event) => {
              setFieldValue?.("estado_cavalete", event.target.value);
            }}
          >
            <MenuItem value={"Completo"}>{"Completo"}</MenuItem>
            <MenuItem value={"Incompleto"}>{"Incompleto"}</MenuItem>
            <MenuItem value={"Danificado"}>{"Danificado"}</MenuItem>
            <MenuItem value={"Enterrado"}>{"Enterrado"}</MenuItem>
            <MenuItem value={"Retirado"}>{"Retirado"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">O cavalete tem fugas?</InputLabel>
          <Select
            labelId="catw_drop"
            value={values.cavalete_tem_fugas}
            label="O cavalete tem fugas?"
            onChange={(event) => {
              setFieldValue?.("cavalete_tem_fugas", event.target.value);
            }}
          >
            <MenuItem value={"Sem fugas"}>{"Sem fugas"}</MenuItem>
            <MenuItem value={"Fugas Antes do Contador"}>
              {"Fugas Antes do Contador"}
            </MenuItem>
            <MenuItem value={"Fugas Depois do Contador"}>
              {"Fugas Depois do Contador"}
            </MenuItem>
            <MenuItem value={"Fuga no Contador"}>{"Fuga no Contador"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">
            Qual é o estado do seu contador?
          </InputLabel>
          <Select
            labelId="catw_drop"
            value={values.estado_do_contador}
            label="Qual é o estado do seu contador?"
            onChange={(event) => {
              setFieldValue?.("estado_do_contador", event.target.value);
            }}
          >
            <MenuItem value={"Bom"}>{"Bom"}</MenuItem>
            <MenuItem value={"Sem Contador"}>{"Sem Contador"}</MenuItem>
            <MenuItem value={"Leitura Não Visível"}>
              {"Leitura Não Visível"}
            </MenuItem>
            <MenuItem value={"Avariado"}>{"Avariado"}</MenuItem>
          </Select>
        </FormControl>

        <div>
          <p className=" text-[18px] font-bold">Para o Inquiridor</p>
        </div>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">Qual é o diâmetro do contador?</InputLabel>
          <Select
            labelId="catw_drop"
            value={values.diametro_contador}
            label="Qual é o diâmetro do contador?"
            onChange={(event) => {
              setFieldValue?.("diametro_contador", event.target.value);
            }}
          >
            <MenuItem value={"15MM"}>{"15MM"}</MenuItem>
            <MenuItem value={"20MM"}>{"20MM"}</MenuItem>
            <MenuItem value={"25MM"}>{"25MM"}</MenuItem>
            <MenuItem value={"50MM"}>{"50MM"}</MenuItem>
            <MenuItem value={"Não Sei"}>{"Não Sei"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">
            A leitura tem sido feita com regularidade?
          </InputLabel>
          <Select
            labelId="catw_drop"
            value={values.regularidade_leitura}
            label="Tem sido feita com regularidade a leitura?"
            onChange={(event) => {
              setFieldValue?.("regularidade_leitura", event.target.value);
            }}
          >
            <MenuItem value={"Sim"}>{"Sim"}</MenuItem>
            <MenuItem value={"Não"}>{"Não"}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="catw_drop">
            Qual é o nivél de satisfação pelos serviços prestados pela ADRMM?
          </InputLabel>
          <Select
            labelId="catw_drop"
            value={values.avaliacao_adrmm}
            label="Qual é o nivél de satisfação pelos serviços prestados pela ADRMM?"
            onChange={(event) => {
              setFieldValue?.("avaliacao_adrmm", event.target.value);
            }}
          >
            <MenuItem value={"Muito Bom"}>{"Muito Bom"}</MenuItem>
            <MenuItem value={"Bom"}>{"Bom"}</MenuItem>
            <MenuItem value={"Máu"}>{"Máu"}</MenuItem>
          </Select>
        </FormControl>

        <CosTextField
          fullWidth
          size="small"
          placeholder="Número do Contador"
          label="Número do Contador"
          value={values.numero_contador}
          errorMessage={errors.numero_contador}
          field="numero_contador"
          setFieldValue={setFieldValue}
        />
        <CosTextField
          fullWidth
          size="small"
          placeholder="Leitura Actual"
          label="Leitura Actual"
          value={values.leitura_actual}
          errorMessage={errors.leitura_actual}
          field="leitura_actual"
          setFieldValue={setFieldValue}
        />
        <FormControl size="small" fullWidth variant="outlined">
          <InputLabel id="possi_drop">
            Tem suspeita de consumo ilegal? *
          </InputLabel>
          <Select
            labelId="possi_drop"
            value={values.suspeita_ilegal}
            label="Tem suspeita de consumo ilegal? *"
            onChange={(event) => {
              setFieldValue?.("suspeita_ilegal", event.target.value);
            }}
          >
            <MenuItem value={"Sim"}>{"Sim"}</MenuItem>
            <MenuItem value={"Não"}>{"Não"}</MenuItem>
          </Select>
        </FormControl>
        <div className="mt-[10px]">
          <p className=" text-[18px] font-bold">Geolocalização e Fotos</p>
        </div>
        <div className="w-full">
          <MyLocaction setFieldValue={setFieldValue} />
        </div>
        <div className="flex flex-1 flex-row w-full">
          <div className="flex flex-1 flex-col items-center gap-[10px]">
            <Input
              style={{ display: "none" }}
              id="image-upload1"
              type="file"
              onChange={handleFoto1}
            />
            <div style={{ marginTop: "20px" }}>
              <img
                className="w-[90px] h-[90px] rounded-[7px] overflow-hidden border-[3px]"
                src={values.foto_contador}
                alt="Selected"
                width="100%"
                height="auto"
              />
            </div>
            <label htmlFor="image-upload1">
              <Button variant="contained" component="span">
                CONTADOR
              </Button>
            </label>
          </div>
          <div className="flex flex-1 flex-col items-center gap-[10px]">
            <Input
              style={{ display: "none" }}
              id="image-upload3"
              type="file"
              onChange={handleFotoBalisa}
            />
            <div style={{ marginTop: "20px" }}>
              <img
                className="w-[90px] h-[90px] rounded-[7px] overflow-hidden border-[3px]"
                src={values.foto_balisa}
                alt="Selected"
                width="100%"
                height="auto"
              />
            </div>
            <label htmlFor="image-upload3">
              <Button variant="contained" component="span">
                BALIZA
              </Button>
            </label>
          </div>
          <div className="flex flex-1 flex-col items-center gap-[10px]">
            <Input
              style={{ display: "none" }}
              id="image-upload2"
              type="file"
              onChange={handleFoto2}
            />
            <div style={{ marginTop: "20px" }}>
              <img
                className="w-[90px] h-[90px] rounded-[7px] overflow-hidden border-[3px]"
                src={values.foto_residencia}
                alt="Selected"
                width="100%"
                height="auto"
              />
            </div>
            <label htmlFor="image-upload2">
              <Button variant="contained" component="span">
                RESIDÊNCIA
              </Button>
            </label>
          </div>
        </div>
      </div>
      <div className="my-[20px]">
        <TextField
          label="Mais informações"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={values.observacao}
          onChange={(event)=>{
            setFieldValue?.('observacao',event.target.value);
          }}
        />
      </div>
      {viewLog?.show && (
        <MyAlert type={viewLog.type} message={viewLog.message} />
      )}
      <div className="flex flex-1 mt-[10px] mb-[40px]">
        <CosButton
          onClick={onClick}
          isLoading={isLoading}
          variant="contained"
          fullWidth
        >
          SALVAR
        </CosButton>
      </div>
    </div>
  );
};

export default ClientForm;
