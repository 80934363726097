export enum UserStatus {
  "Activo" = "Activo",
  "Bloqueada" = "Bloqueada",
}

export enum UserType {
  "Administrador" = "Administrador",
  "Linha do Cliente" = "Linha do Cliente",
  "Brigadista" = "Brigadista",
}

interface User {
  id?: number;
  nome: string;
  email?: string;
  telefone?: string | null;
  telefone2?: string | null;
  numero_bi: string | null;
  data_nascimento: string;
  area_operacional: string | null;
  bairro: string | null;
  endereco: string | null;
  role?: UserType;
  senha?: string | null;
  token?: string;
  hash_confirmation?: string | null;
  status?: UserStatus;
}

export type { User };
