import Pagination from "@mui/material/Pagination";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import FiltroMenuClientes from "../../components/filtros/filter-menu-clientes";
import { ModalStateProps } from "../../components/modal";
import { Constants } from "../../constants/home-constants";
import { useBetterState } from "../../hooks/use-better-state";
import ClienteRelatorioViewModel from "../../viewmodel/relatorios/clientes";

const MapaGeral = () => {
  const {
    viewLog,
    clienteState,
    currentView,
    clientes,
    setClientes,
    loadPage,
    loadClientes,
    listenerNav,
    handleClick,
    handleClienteState,
  } = ClienteRelatorioViewModel();

  const deleteData = useBetterState<any>({
    isLoading: false,
    data: 0,
  });
  const modalState = useBetterState<ModalStateProps>({
    show: false,
  });

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex flex-1 px-[40px] pt-[20px] flex-col">
          <div className="flex flex-1">
            <span className=" text-[20px] font-bold">Lista de Clientes</span>
          </div>
          <div>
            <FiltroMenuClientes
              valorPesquisa={clienteState.value.value}
              handleChangeNc={handleClienteState}
              listener={listenerNav}
              onClick={handleClick}
            />
          </div>
          <div className="w-full flex flex-1">
            {viewLog.show && (
              <div className="border-[1px] border-blue-800 w-full flex flex-row justify-center items-center mx-[5px] mb-[5px] rounded-[5px]">
                <p className="text-center">{viewLog.message}</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-[20px] overflow-auto mb-[30px] tablet:px-[30px]">
          <div className="w-full h-[500px]">
            <APIProvider
              apiKey={"AIzaSyB5v8QIe5AcXYGeGwZuGUcnZh_21ZyBHxM"}
              onLoad={() => {
                console.log("Mapa carregado !!!");
              }}
            >
              <Map
                defaultZoom={8}
                defaultCenter={{ lat: -25.9566848, lng: 32.5790043 }}
              >
                {clientes.data.map((item) => {
                  console.log(item);
                  return (
                    <Marker
                      position={{
                        lat: Number(item.latitude),
                        lng: Number(item.longitude),
                      }}
                      icon={
                        item.situacao_entrevistado ===
                        Constants.situacaoCadastro.irregular
                          ? "http://maps.google.com/mapfiles/ms/icons/red.png"
                          : item.situacao_entrevistado ===
                              Constants.situacaoCadastro.correcto
                            ? "http://maps.google.com/mapfiles/ms/icons/green.png"
                            : item.situacao_entrevistado ===
                                Constants.situacaoCadastro.incompleto
                              ? "http://maps.google.com/mapfiles/ms/icons/yellow.png"
                              : "http://maps.google.com/mapfiles/ms/icons/purple.png"
                      }
                    />
                  );
                })}
              </Map>
            </APIProvider>
          </div>
          <div className="mt-[20px] flex flex-1 justify-end">
            <Pagination
              count={clientes.pagination.totalPages}
              color="primary"
              onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                loadPage(page);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapaGeral;
