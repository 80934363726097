import { useNavigate } from "react-router-dom";
import api from "../../api";
import { HTTP_STATUS } from "../../api/api-response-status";
import { useBetterState } from "../../hooks/use-better-state";
import useViewLog from "../../hooks/use-view-log";
import useAppInfo from "../../zustand/app-info-zus";

const ForgotConfirmationViewModel = () => {
  const { userC, setUserC } = useAppInfo();
  const isLoading = useBetterState<boolean>(false);
  const navigate = useNavigate();

  const { viewLog, setNewLog } = useViewLog();

  const submitCode = (code: string) => {
    isLoading.value = true;
    api.post(
      {
        path: "/api/user/confirmation",
        data: {
          email: userC?.email,
          hash_confirmation: code + userC?.hash_confirmation,
        },
        params: {
          type: "RECOVER",
        },
      },
      (data) => {
        setUserC({
          ...userC,
          hash_confirmation: code + userC?.hash_confirmation,
        });
        isLoading.value = false;
        navigate("/authentication/forgot-newpassword");
      },
      (error) => {
        isLoading.value = false;
        if (error.status === HTTP_STATUS.UNAUTHORIZED) {
          setNewLog({
            type: "WARNING",
            message: "O códico de confirmação está errado.",
          });
        } else if (error.status === HTTP_STATUS.NETWORK_ERROR) {
          setNewLog({
            type: "WARNING",
            message: "Erro de conexão de internet.",
          });
        } else {
          setNewLog({
            type: "WARNING",
            message: "Erro inesperado, se persistir contacte o administrador.",
          });
        }
      },
    );
  };

  return {
    isLoading,
    viewLog,
    submitCode,
  };
};

export default ForgotConfirmationViewModel;
