import yup, { object, string } from "yup";

const defaultValues = {
  codico: "123456",
  senha: "123456789",
  senhaConfirmar: "123456789",
};
const schema: yup.Schema = object({
  codico: string()
    .matches(/^\d{6}$/, "Códico de confirmação inválido(6 digitos). ")
    .required("Por favor, forneça um códico de confirmação."),
  senha: string()
    .required("Por favor, forneça a nova senha.")
    .matches(/^\d{6,15}$/, "A senha deve ser 6 digitos no minímo."),
  senhaConfirmar: string()
    .required("Por favor, forneça a nova senha.")
    .matches(/^\d{6,15}$/, "A senha deve ser 6 digitos no minímo."),
});

const geral = {
  values: defaultValues,
  schema: schema,
};

export default geral;
