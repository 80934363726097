import { PieChart } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";
// import TitleGraph from "../components/home/title-graph";
import TitleGraph from "../components/home/title-graph";
import { Constants } from "../constants/home-constants";
import HomeViewModel from "../viewmodel/home";
import "./../components/menu/nav-bar.css";

const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300, 400, 11500];
const xLabels = [
  Constants.areasOperacionais[0].name,
  Constants.areasOperacionais[1].name,
  Constants.areasOperacionais[2].name,
  Constants.areasOperacionais[3].name,
  Constants.areasOperacionais[4].name,
  Constants.areasOperacionais[5].name,
  Constants.areasOperacionais[6].name,
  Constants.areasOperacionais[7].name,
  Constants.areasOperacionais[8].name,
];

const Home = () => {
  const { estado, situacao } = HomeViewModel();

  return (
    <div className="w-full px-[10px] md:px-[15px] py-[8px] relative">
      <div className=" bg-blue-100 flex flex-1 flex-col elevated min-h-[300px]">
        <div className="flex-1 px-[30px]">
          <div className="mt-[15px]">
            <TitleGraph
              title="Gráfico Estatistico (Áreas Operacionais)"
              description="Relatório de Registo de Clientes por Áreas Operacionaiss"
              value={300225}
            />
          </div>
          <LineChart
            className="flex-1"
            height={300}
            series={[{ data: pData }]}
            xAxis={[{ scaleType: "point", data: xLabels }]}
          />
        </div>
      </div>
      <div className="flex flex-1  flex-col gap-[30px] tablet:flex-row tablet:mt-[30px] tablet:mb-[25px]">
        <div className="flex flex-1 flex-col min-h-[300px] justify-between pt-[55px] pb-[30px] elevated">
          <div className="mx-[30px] py-[10px]">
            <TitleGraph
              title="Gráfico Estatístico (Situação dos Clientes)"
              description="Relatório do Estado dos Clientes"
              value={situacao.total}
            />
          </div>
          <div className="flex flex-1 flex-col justify-center items-center">
            <PieChart
              className="w-100px]"
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: situacao.Correcto,
                      label: Constants.situacaoCadastro.correcto,
                      color: "#007C00",
                    },
                    {
                      id: 1,
                      value: situacao.Incompleto,
                      label: Constants.situacaoCadastro.incompleto,
                      color: "#FCC900",
                    },
                    {
                      id: 2,
                      value: situacao.Irregular,
                      label: Constants.situacaoCadastro.irregular,
                      color: "#E50212",
                    },
                    {
                      id: 3,
                      value: situacao.null,
                      label: Constants.situacaoCadastro.semInfo,
                      color: "#7900CB",
                    },
                  ],
                },
              ]}
              height={150}
              width={400}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col min-h-[300px] justify-between pt-[55px] pb-[30px] elevated">
          <div className="flex flex-1 flex-col px-[20px]">
            <div className="mx-[30px] py-[10px]">
              <TitleGraph
                title="Gráfico Estatistico (Estado do Contador)"
                description="Relatório do Estado dos Contador"
                value={estado.total}
              />
            </div>
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    Constants.estadoContador.bom,
                    Constants.estadoContador.leituraInv,
                    Constants.estadoContador.avariado,
                    Constants.estadoContador.semContador,
                    Constants.estadoContador.semInfo,
                  ],
                },
              ]}
              series={[
                {
                  data: [
                    estado.Bom,
                    estado.NaoV,
                    estado.Avariado,
                    estado.Sem,
                    estado.null,
                  ],
                },
              ]}
              height={300}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
