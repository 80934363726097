import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CosButton from "./CosButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};

interface ModalStateProps {
  show: boolean;
  message?: string;
  data?: any;
}

export type { ModalStateProps };

interface MyModalModalProps {
  state?: ModalStateProps;
  message?: string;
  handleYes?: (id?: number) => void;
  handleNo?: () => void;
  handleDismiss?: () => void;
}

export default function MyModalModal({
  state,
  handleYes,
  handleNo,
  handleDismiss,
}: MyModalModalProps) {
  return (
    <div>
      <Modal
        open={state?.show ?? false}
        onClose={handleDismiss}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-1 flex-col  w-full">
            <div className="w-full flex flex-1 flex-row justify-center pt-[10px] pb-[30px]">
              <p className=" text-center text-black-900">{state?.message}</p>
            </div>
            <div className="flex flex-row w-full justify-evenly">
              <CosButton size="small" onClick={handleNo}>
                VOLTAR
              </CosButton>
              <CosButton
                size="small"
                onClick={() => {
                  handleYes?.(state?.data);
                }}
              >
                CONFIRMAR
              </CosButton>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
