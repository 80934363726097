import { useEffect, useState } from "react";

interface ViewLogProps {
  show: boolean;
  type?: "SUCCESS" | "WARNING" | "ERROR";
  message?: string;
}

export type { ViewLogProps };

const useViewLog = () => {
  const [viewLog, setViewLog] = useState<ViewLogProps>({
    show: false,
  });

  useEffect(() => {
    if (viewLog.show) {
      setTimeout(() => {
        setViewLog({
          ...viewLog,
          show: false,
        });
      }, 5000);
    }
  }, [viewLog]);

  const setNewLog = (viewLog: Omit<ViewLogProps, "show">) => {
    setViewLog({
      show: true,
      ...viewLog,
    });
  };

  return {
    viewLog,
    setNewLog,
  };
};

export default useViewLog;
